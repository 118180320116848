export class SelectableList<T> extends Array<SelectableItem<T>> {
  public test: number = 2;

  constructor() {
    super();
  }

  public static fromArray<T>(items: Array<T>) : SelectableList<T>{
    var array = new SelectableList<T>();
    
    for(var i = 0; i < items.length; i++){
      array.push(new SelectableItem<T>(items[i]));
    }
    
    return array;
  }

  public anySelected: Function = () : boolean => {
    return this.getSelected().length > 0;
  }

  public getSelected:Function = () :Array<T> => {
    var result = new Array<T>();
    
    for(var i = 0; i < this.length; i++) {
      if(this[i].selected)
        result.push(this[i].data as any);
    }

    return result;
  }

  public setSelected:Function = (fn: Function) : void  => {
    for(var i = 0; i < this.length; i++) {
      this[i].selected = fn(this[i].data, i) ? true : false;
    }
  }

  public selectAll:Function = () : void => {
    for(var i = 0; i < this.length; i++) {
      this[i].selected = true;
    }
  }

  public deselectAll:Function = () : void => {
    for(var i = 0; i < this.length; i++) {
      this[i].selected = false;
    }
  }


}

export class SelectableItem<T> {
  public selected: boolean;
  public data: T;

  constructor(data: T){
    this.data = data;
  }

}