import {TextService} from "shared/services/text.service";
import {TranslationModel} from "shared/models/translation.model";
import {FileModel} from "shared/models/file.model";
import CodeMirror from "codemirror";

const SELECTOR_TEXT_PATTERN:RegExp = /@{[^}]+}/g;
const SELECTOR_RENDER_PATTERN:RegExp = /@Render\([^\)]+\)/g;

const SELECTOR_TYPE_TEXT:string = "text";
const SELECTOR_TYPE_RENDER:string  = "render";

export class CodeEditorMode {

    constructor(private textService: TextService, private originalTranslation: TranslationModel, private onChange: Function) { }

    public init() {
        var self = this;
         this.defineBFMode();

        var updateSmartSelectors = (editor, range, userInput = false) => {
            var transform = false;
            var markedSpansCount = 0;
            var doc = editor.getDoc();

            var line = doc.getLineHandle(range.from.line);
            var lineNr = doc.getLineNumber(line);

            // If line has any selectors
            if (line.markedSpans) {
                var markers = line.markedSpans;
                markedSpansCount = line.markedSpans.length;

                // Clear all old selectors
                for (var i = 0; i < markers.length; i++) {
                    var marker = markers[i];
                    var id = marker.marker.replacedWith.id;

                    if (marker.marker.selectorType === SELECTOR_TYPE_TEXT)
                        doc.replaceRange("@{" + id + "}", { line: lineNr, ch: marker.from }, { line: lineNr, ch: marker.to });

                    if (marker.marker.selectorType === SELECTOR_TYPE_RENDER)
                        doc.replaceRange("@Render(" + id + ")", { line: lineNr, ch: marker.from }, { line: lineNr, ch: marker.to });

                    marker.marker.clear();
                }
            }

            editor.eachLine(range.from.line, range.to.line + 1, (line) => {
                line.text = line.text.replace(SELECTOR_TEXT_PATTERN, (a) => {
                    var key = a.replace(/\@\{/g, "").replace(/\}/g, "");
                    var text = this.textService.getTextByKey(key, this.originalTranslation);
                    if (!text) {
                        text = this.textService.addText(key, this.originalTranslation);
                        return "@{" + text.key + "}";
                    } else {
                        return a;
                    }
                });

                line.text = line.text.replace(SELECTOR_RENDER_PATTERN, function (a) {
                    var id = a.replace(/\@Render\(/g, "").replace(/\)/g, "");
                    return "@Render(" + id + ")";
                });

            });

            var renderWidgets =  (text, lineNumber, type) => {
                var pattern:RegExp;

                if (type === SELECTOR_TYPE_TEXT)
                    pattern = SELECTOR_TEXT_PATTERN;

                if (type === SELECTOR_TYPE_RENDER)
                    pattern = SELECTOR_RENDER_PATTERN;

                var match = pattern.exec(text);

                while (match != null) {
                    var string = match[0];
                    var start = match.index;
                    var end = start + string.length;

                    var mark = doc.markText(
                    {
                        line: lineNumber,
                        ch: start
                    },
                    {
                        line: lineNumber,
                        ch: end
                    },
                    {
                        atomic: false,
                        replacedWith: this.makeWidget(string, doc, editor, type, userInput)
                    });

                    mark.selectorType = type;

                    match = pattern.exec(text);
                };

            };


            editor.eachLine(range.from.line, range.to.line + 1, function (line) {
                var text = line.text;
                var textHit = text.match(SELECTOR_TEXT_PATTERN);
                var renderHit = text.match(SELECTOR_RENDER_PATTERN);
                var hitCount = (textHit ? textHit.length : 0) + (renderHit ? renderHit.length : 0);

                if (hitCount > markedSpansCount) {
                    transform = true;
                } else {
                    transform = false;
                }

                var lineNumber = doc.getLineNumber(line);

                renderWidgets(text, lineNumber, SELECTOR_TYPE_TEXT);
                renderWidgets(text, lineNumber, SELECTOR_TYPE_RENDER);

            });

            return transform;
        }

        function batchUpdate(editor, change) {
            var didTransform = false;
            if (change.origin === "api") {
                updateSmartSelectors(editor, change);
            } else {
                while (change) {
                    if ((change.text.length === 2 && change.text[0] === "" && change.text[1] === "") && change.origin != "paste") {
                        didTransform = updateSmartSelectors(editor, change, true);
                        change.canceled = didTransform;
                    }
                    change = change.next;
                }
                if(didTransform) {
                    self.onChange();
                }
            }
        }

        function batchUpdateAfter(editor, change) {
            if (change.origin === "paste" || change.origin === "api-before") {
                updateSmartSelectors(editor, {
                    from: { line: editor.firstLine(), ch: 0 },
                    to: { line: editor.lastLine() + 1, ch: 0 }
                });
            }
        }

        CodeMirror.defineOption("smartSelector", true, function (editor, current, past) {

            //Clear eventhandlers
            editor.off("change", batchUpdateAfter);
            editor.off("beforeChange", batchUpdate);

            editor.on("beforeChange", batchUpdate);
            editor.on("change", batchUpdateAfter);

            updateSmartSelectors(editor, {
                from: { line: editor.firstLine(), ch: 0 },
                to: { line: editor.lastLine() + 1, ch: 0 }
            });

        });
    };

    public static getModeFromFile(file: FileModel) {
        if (file.isScript()) {
            return "javascript";
        } else if (file.isMarkup()) {
            return "htmlmixed";
        } else if (file.isStyleSheet()) {
            return "css";
        }
    }

   private makeWidget(string, doc, editor, selectorType, user):any {
        var key = "";
        var html = "";
        var id = "";
        var name = '';

        if (selectorType === SELECTOR_TYPE_TEXT) {
            key = string.replace(/\@\{/g, "").replace(/\}/g, "");
            var text = this.textService.getTextByKey(key, this.originalTranslation);
            var value = text.value;
            value = value.replace(/(\r\n|\n|\r)/gm, "");
            value = value.length > 40 ? (value.substring(0, 40) + '...') : value;
            id = text.key;

            name = $('<div/>').html(text.name).text()
            html = $('<div/>').html(value).text();

        }

        var widget = document.createElement("span");
        widget.id = id;
        widget.innerHTML = html;
        widget.className = "cm-smart-selector type-" + selectorType;

        var widgetName = document.createElement('span');
        widgetName.className = 'cm-smart-selector-name';
        widgetName.innerHTML = name || 'untitled';
        widget.insertBefore(widgetName, widget.firstChild);
        return widget;
    }

    private defineBFMode() : void{
        CodeMirror.defineMode("bfmode", function (config, parserConfig) {
            var bfmodeOverlay = {
                token: function (stream, state) {
                    var ch;

                    if (stream.match("@{")) {
                        while ((ch = stream.next()) != null)

                            if (ch == "}") {
                                return "bfmode";
                            }
                    } else if (stream.match("@Render(")) {
                        while ((ch = stream.next()) != null)
                            if (ch == ")") {
                                return "bfmode render";
                            }
                    }
                    while (stream.next() != null && !stream.match("@{", false) && !stream.match("@Render(", false)) { }
                    return null;
                }

            };
            return  CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "htmlmixed"), bfmodeOverlay);

        });

    }
}
