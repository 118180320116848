import { Component, OnInit, OnDestroy, Renderer2, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { BFUtilsService } from '../../services/bfUtils.service';
import { BFConstants } from '../../models/bfConstants';
import { BFDialogContainer, BFOverlayContainer } from '../../containers/index';

import 'jquery';

export class BFDialogComponentConfig {
    public clickOutsideToClose: boolean;

    constructor(properties: Partial<BFDialogComponentConfig> = {}) {
        Object.assign(this, properties);
    }
}

@Component({
    selector: 'bfDialog',
    templateUrl: './bfDialog.component.html',
    styleUrls: ['./bfDialog.component.scss'],
    standalone: true
})
export class BFDialogComponent implements OnInit, OnDestroy {
    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() config: BFDialogComponentConfig;

    constructor(private dialogContainer: BFDialogContainer,
        private overlayContainer: BFOverlayContainer) { }

    ngOnInit(): void {
        //If dialogcontainer contains one instance of a dialog
        //set body overflow css setting
        if (this.dialogContainer.getCount() === 1 &&
            this.overlayContainer.getCount() === 0) {
            BFUtilsService.toggleBodyOverflow(true);
        }
    }

    ngOnDestroy(): void {
        this.resetBodyOverflow();
    }

    public close() {
        this.resetBodyOverflow();
        this.onClose.emit(true);
    }

    public closeByClickOutside(event: MouseEvent) {
        //Click must be on outer element
        if (event && event.currentTarget === event.target && this.config && this.config.clickOutsideToClose) {
            this.close();
        }
    }

    private resetBodyOverflow(): void {
        //If dialogcontainer only contains one instance of a dialog
        //remove body overflow css settings
        if (this.dialogContainer.getCount() < 2 &&
            this.overlayContainer.getCount() === 0) {
            BFUtilsService.toggleBodyOverflow(false);
        }
    }

    @HostListener('document:keydown', ['$event'])
    private handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.keyCode) {
            case BFConstants.KEYS.ESCAPE:
                this.close();
                break;
        }
    }
}
