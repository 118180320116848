// libs
import { Component, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet } from '@angular/router';
import { BFMaterial } from '../../../libs/material';

// app

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {LandingPageService} from "shared/services/landingPage.service";
import {UserService} from "shared/services/user.service";
import {TextService} from "shared/services/text.service";
import {HotkeysService} from "shared/services/hotkeys.service";

@Component({
    selector: 'mainView',
    styleUrls: ['main.view.component.scss'],
    templateUrl: 'main.view.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class MainViewComponent {
    private loaded: boolean;
    private landingPageId: string;
    private previewUrl: string;
    public designRoute: boolean;

    constructor(private readonly bfMaterial: BFMaterial,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly landingPageService: LandingPageService,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly textService: TextService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly hotkeysService: HotkeysService) {

        this.bfMaterial.init(viewContainerRef);

        this.activatedRoute.params.subscribe((params: any) => {

            // init services so essential data is available

            this.userService.init(params.accountId, params.brandId);
            this.landingPageService.init(params.id);
            this.textService.init();
        });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let segment: string = event.urlAfterRedirects.substr(event.urlAfterRedirects.lastIndexOf('/') + 1);
                if (segment === 'design') {
                    this.designRoute = true;
                } else {
                    this.designRoute = false;
                }
            }
        });

        // Setup hotkeys for switching between code/design view
        this.hotkeysService.bindKey(['command+2', 'ctrl+2'], () => {
            this.router.navigate(['./code'], { relativeTo: this.activatedRoute });
        });

        this.hotkeysService.bindKey(['command+1', 'ctrl+1'], () => {
            this.router.navigate(['./design'], { relativeTo: this.activatedRoute });
        });
    }
}
