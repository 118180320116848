import {Component, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {SentinelService} from "@bannerflow/sentinel";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
    private sentinelService = inject(SentinelService);
    constructor() { }
}
