
import { Route } from '@angular/router';
import { ShowcaseView } from './views/showcase.view.component';

export const showcaseRoutes: Route[] = [
    {
        path: 'showcase/:accountSlug/:brandId/:landingPageId',
        children: [
            {
                path: ':translationId',
                component: ShowcaseView
            }
        ]
    }
];