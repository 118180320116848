
import 'codemirror';
declare var CodeMirror : any;

export class SearchAddon {
    private static queryDialog:string = 'Search: <input spellcheck="false" type="text" style="width: 10em" class="CodeMirror-search-field"/>';
    private static replaceQueryDialog:string = ' <input spellcheck="false" type="text" style="width: 10em" class="CodeMirror-search-field"/>';
    private static replacementQueryDialog:string = 'With: <input spellcheck="false" type="text" style="width: 10em" class="CodeMirror-search-field"/>';
    private static doReplaceConfirm:string = "Replace? <button>Yes</button> <button>No</button> <button>All</button> <button>Stop</button>";


    public static init(): void {
        var self = this;
        CodeMirror.commands.find = function(cm) {self.clearSearch(cm); self.doSearch(cm);};
        CodeMirror.commands.findPersistent = function(cm) { self.clearSearch(cm); self.doSearch(cm, false, true);};
        CodeMirror.commands.findPersistentNext = function(cm) {self.doSearch(cm, false, true, true);};
        CodeMirror.commands.findPersistentPrev = function(cm) {self.doSearch(cm, true, true, true);};
        CodeMirror.commands.findNext = self.doSearch;
        CodeMirror.commands.findPrev = function(cm) {self.doSearch(cm, true);};
        CodeMirror.commands.clearSearch = self.clearSearch;
        CodeMirror.commands.replace = self.replace;
        CodeMirror.commands.replaceAll = function(cm) {self.replace(cm, true);};
    }


    private static searchOverlay(query, caseInsensitive): any {
        if (typeof query == "string")
        query = new RegExp(query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"), caseInsensitive ? "gi" : "g");
        else if (!query.global)
        query = new RegExp(query.source, query.ignoreCase ? "gi" : "g");

        return {token: function(stream) {
        query.lastIndex = stream.pos;
        var match = query.exec(stream.string);
        if (match && match.index == stream.pos) {
            stream.pos += match[0].length || 1;
            return "searching";
        } else if (match) {
            stream.pos = match.index;
        } else {
            stream.skipToEnd();
        }
        }};
    }

    private static getSearchState(cm) {
        return cm.state.search || (cm.state.search = new SearchState());
    }

    private static queryCaseInsensitive(query) {
        return typeof query == "string" && query == query.toLowerCase();
    }

    private static getSearchCursor(cm, query, pos = null) {
        // Heuristic: if the query string is all lowercase, do a case insensitive search.
        return cm.getSearchCursor(query, pos, this.queryCaseInsensitive(query));
    }

    private static persistentDialog(cm, text, deflt, onEnter, onKeyDown) {
        var self = this;
        cm.openDialog(text, onEnter, {
        value: deflt,
        selectValueOnOpen: true,
        closeOnEnter: false,
        onClose: function() { self.clearSearch(cm); },
        onKeyDown: onKeyDown
        });
    }

    private static dialog(cm, text, shortText, deflt, f) {
        if (cm.openDialog) cm.openDialog(text, f, {value: deflt, selectValueOnOpen: true});
        else f(prompt(shortText, deflt));
    }

    private static confirmDialog(cm, text, shortText, fs) {
        if (cm.openConfirm) cm.openConfirm(text, fs);
        else if (confirm(shortText)) fs[0]();
    }

    private static parseString(string) {
        return string.replace(/\\(.)/g, function(_, ch) {
        if (ch == "n") return "\n"
        if (ch == "r") return "\r"
        return ch
        })
    }

    private static parseQuery(query) {
        var isRE = query.match(/^\/(.*)\/([a-z]*)$/);
        if (isRE) {
        try { query = new RegExp(isRE[1], isRE[2].indexOf("i") == -1 ? "" : "i"); }
        catch(e) {} // Not a regular expression after all, do a string search
        } else {
        query = this.parseString(query)
        }
        if (typeof query == "string" ? query == "" : query.test(""))
        query = /x^/;
        return query;
    }

    private static startSearch(cm, state, query) {
        state.queryText = query;
        state.query = this.parseQuery(query);
        cm.removeOverlay(state.overlay, this.queryCaseInsensitive(state.query));
        state.overlay = this.searchOverlay(state.query, this.queryCaseInsensitive(state.query));
        cm.addOverlay(state.overlay);
        if (cm.showMatchesOnScrollbar) {
        if (state.annotate) { state.annotate.clear(); state.annotate = null; }
        state.annotate = cm.showMatchesOnScrollbar(state.query, this.queryCaseInsensitive(state.query));
        }
    }

    private static doSearch(cm, rev = false, persistent = false, immediate = false) {
        var self = this;
        var state = this.getSearchState(cm);
        if (state.query) return this.findNext(cm, rev);
        var q = cm.getSelection() || state.lastQuery;
        if (persistent && cm.openDialog) {
        var hiding = null

        var searchNext = function(query, event) {
            CodeMirror.e_stop(event);
            if (!query) return;
            if (query != state.queryText) {
            self.startSearch(cm, state, query);
            state.posFrom = state.posTo = cm.getCursor();
            }
            if (hiding) hiding.style.opacity = 1
            self.findNext(cm, event.shiftKey, function(_, to) {
            var dialog
            if (to.line < 3 && document.querySelector &&
                (dialog = cm.display.wrapper.querySelector(".CodeMirror-dialog")) &&
                dialog.getBoundingClientRect().bottom - 4 > cm.cursorCoords(to, "window").top)
                (hiding = dialog).style.opacity = .4
            })
        };

        this.persistentDialog(cm, this.queryDialog, q, searchNext, function(event, query) {
            var keyName = CodeMirror.keyName(event)
            var cmd = CodeMirror.keyMap[cm.getOption("keyMap")][keyName]
            if (!cmd) cmd = cm.getOption('extraKeys')[keyName]
            if (cmd == "findNext" || cmd == "findPrev" ||
            cmd == "findPersistentNext" || cmd == "findPersistentPrev") {
            CodeMirror.e_stop(event);
            self.startSearch(cm, self.getSearchState(cm), query);
            cm.execCommand(cmd);
            } else if (cmd == "find" || cmd == "findPersistent") {
            CodeMirror.e_stop(event);
            searchNext(query, event);
            }
        });
        if (immediate && q) {
            this.startSearch(cm, state, q);
            this.findNext(cm, rev);
        }
        } else {
            this.dialog(cm, this.queryDialog, "Search for:", q, function(query) {
                if (query && !state.query) cm.operation(function() {
                self.startSearch(cm, state, query);
                state.posFrom = state.posTo = cm.getCursor();
                self.findNext(cm, rev);
                });
            });
        }
    }

    private static findNext(cm, rev, callback = null) {
        var self = this;
        cm.operation(function() {
            var state = self.getSearchState(cm);
            var cursor = self.getSearchCursor(cm, state.query, rev ? state.posFrom : state.posTo);
            if (!cursor.find(rev)) {
            cursor = self.getSearchCursor(cm, state.query, rev ? CodeMirror.Pos(cm.lastLine()) : CodeMirror.Pos(cm.firstLine(), 0));
            if (!cursor.find(rev)) return;
            }
            cm.setSelection(cursor.from(), cursor.to());
            cm.scrollIntoView({from: cursor.from(), to: cursor.to()}, 20);
            state.posFrom = cursor.from(); state.posTo = cursor.to();
            if (callback) callback(cursor.from(), cursor.to())
     });
    }

    private static clearSearch(cm) {
        var self = this;
        cm.operation(function() {
            var state = self.getSearchState(cm);
            state.lastQuery = state.query;
            if (!state.query) return;
            state.query = state.queryText = null;
            cm.removeOverlay(state.overlay);
            if (state.annotate) { state.annotate.clear(); state.annotate = null; }
        });
    }

    private static replaceAll(cm, query, text) {
        var self = this;
        cm.operation(function() {
        for (var cursor = self.getSearchCursor(cm, query); cursor.findNext();) {
            if (typeof query != "string") {
            var match = cm.getRange(cursor.from(), cursor.to()).match(query);
            cursor.replace(text.replace(/\$(\d)/g, function(_, i) {return match[i];}));
            } else cursor.replace(text);
        }
        });
    }

    private static replace(cm, all) {
        var self = this;
        if (cm.getOption("readOnly")) return;
        var query = cm.getSelection() || this.getSearchState(cm).lastQuery;
        var dialogText = all ? "Replace all:" : "Replace:"
        this.dialog(cm, dialogText + this.replaceQueryDialog, dialogText, query, function(query) {
        if (!query) return;
        query = this.parseQuery(query);
        this.dialog(cm, this.replacementQueryDialog, "Replace with:", "", function(text) {
            text = self.parseString(text)
            if (all) {
                self.replaceAll(cm, query, text)
            } else {
                self.clearSearch(cm);
            var cursor = self.getSearchCursor(cm, query, cm.getCursor("from"));
            var advance = function() {
                var start = cursor.from(), match;
                if (!(match = cursor.findNext())) {
                cursor = self.getSearchCursor(cm, query);
                if (!(match = cursor.findNext()) ||
                    (start && cursor.from().line == start.line && cursor.from().ch == start.ch)) return;
                }
                cm.setSelection(cursor.from(), cursor.to());
                cm.scrollIntoView({from: cursor.from(), to: cursor.to()});
                self.confirmDialog(cm, self.doReplaceConfirm, "Replace?",
                            [function() {doReplace(match);}, advance,
                            function() {self.replaceAll(cm, query, text)}]);
            };
            var doReplace = function(match) {
                cursor.replace(typeof query == "string" ? text :
                            text.replace(/\$(\d)/g, function(_, i) {return match[i];}));
                advance();
            };
            advance();
            }
        });
        });
    }

}

class SearchState{
    public posFrom;
    public posTo;
    public lastQuery;
    public query;
    public overlay;

    constructor() {

    }
}
