import {UserModel} from "shared/models/user.model";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserService {
    private _loadPromise: Promise<UserModel>;

    public init(accountSlug: string, brandId: string, force = false): Promise<UserModel> {
        if (!this._loadPromise || force) {
            this._loadPromise = Observable.create(observer => {
                observer.next(new UserModel(accountSlug, brandId));
                observer.complete();
            }).toPromise();
        }
        return this._loadPromise;
    }

    public get(): Promise<UserModel> {
        return this._loadPromise;
    }
}
