import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { TranslationModel } from 'shared/models/translation.model';

export interface ILandingPageState {
  landingPage: LandingPageModel;
  selectedTranslations: TranslationModel[]
  cnames: any;
  type: 'publish' | 'unpublish';
}

@Injectable({
  providedIn: 'root',
})
export class LandingPageStore
	  extends ComponentStore<ILandingPageState> {

    constructor() {
      super({
        landingPage: undefined,
        selectedTranslations: [],
        cnames: undefined,
        type: undefined,
      });
    }

    public readonly setlandingPage = this.effect((landingPage$: Observable<LandingPageModel>) =>
      landingPage$.pipe(tap((landingPage: LandingPageModel) => this.updateLandingPageState(landingPage))),
    );

    public readonly setSelectedTranslations = this.effect((selectedTranslations$: Observable<TranslationModel[]>) =>
      selectedTranslations$.pipe(tap((selectedTranslations: TranslationModel[]) => this.updateSelectedTranslations(selectedTranslations))),
    );

    public readonly setCnames = this.effect((cnames$: Observable<any>) =>
      cnames$.pipe(tap((cnames: any) => this.updateCnames(cnames))),
    );

    public readonly setType = this.effect((type$: Observable<'publish' | 'unpublish'>) =>
      type$.pipe(tap((type: 'publish' | 'unpublish') => this.updateType(type))),
    );

    private readonly updateLandingPageState = this.updater(
      (state: ILandingPageState, landingPage: LandingPageModel) => ({
        ...state,
        landingPage,
      }),
    );

    private readonly updateSelectedTranslations = this.updater(
      (state: ILandingPageState, selectedTranslations: TranslationModel[]) => ({
        ...state,
        selectedTranslations,
      }),
    );

    private readonly updateCnames = this.updater(
      (state: ILandingPageState, cnames: any) => ({
        ...state,
        cnames,
      }),
    );

    private readonly updateType = this.updater(
      (state: ILandingPageState, type: 'publish' | 'unpublish') => ({
        ...state,
        type,
      }),
    );

    public loadLandingPage(): Observable<LandingPageModel> {
      return this.select((state) => state.landingPage);
    }

    public loadSelectedTranslations(): Observable<TranslationModel[]> {
      return this.select((state) => state.selectedTranslations);
    }

    public loadCnames(): Observable<any> {
      return this.select((state) => state.cnames);
    }

    public loadType(): Observable<'publish' | 'unpublish'> {
      return this.select((state) => state.type);
    }
}
