<ui-loader *ngIf="loading"></ui-loader>
<div class="addTranslations" *ngIf="!loading">
    <div class="closeOverlay" (click)="cancel()">
        <ui-svg-icon class="ui-icon" icon="close" />
        <div class="closeText">
            <span>close</span>
            <span>(esc)</span>
        </div>
    </div>
    <div class="addTranslations__wrapper">
        <!--HEADER-->
        <div class="addTranslations__header">
            <div class="addTranslations__headerTitle">
                Manage versions
                <span *ngIf="step > 1">
                    <i clasS="bf-icon bf-icon-arrow-right-a"></i>
                    {{localizations.getSelected().length}}
                    new
                    {{localizations.getSelected().length > 1 ? 'versions' : 'version'}}
                </span>
            </div>
            <div class="addTranslations__headerButtons"
                *ngIf="showAddVersions">

                <ui-button
                    *ngIf="step == 1"
                    id="interaction-close-add-translations-landingpage-btn"
                    type="default"
                    (click)="closeAddVersions()"
                    text="Cancel">
                </ui-button>

                <ui-button
                    *ngIf="step > 1"
                    id="interaction-back-add-translations-landingpage-btn"
                    type="default"
                    (click)="prev()"
                    text="Back">
                </ui-button>

                <ui-button
                    *ngIf="step == 1"
                    id="interaction-next-add-translations-landingpage-btn"
                    type="primary"
                    [disabled]="!localizations.anySelected()"
                    (click)="next()"
                    text="Next">
                </ui-button>

                <!--TODO: add validation msg-->

                <ui-button
                    *ngIf="step > 1"
                    id="interaction-add-add-translations-landingpage-btn"
                    type="primary"
                    [disabled]="!isValid"
                    (click)="next()"
                    [text]="'Add ' + localizations.getSelected().length + ' ' + (localizations.getSelected().length > 1 ? 'versions' : 'version')">
                </ui-button>

            </div>
        </div>

        <!--CONTENT-->
        <div class="addTranslations__content">
            <div class="addTranslations__step1"
                  *ngIf="step == 1">
                <div class="addTranslations__addButton"
                    *ngIf="!showAddVersions"
                    (click)="openAddVersions()">
                    Add new versions
                </div>

                <div class="addTranslations__addPanel"
                    *ngIf="showAddVersions">
                    <div class="addTranslations__addPanelTitle">New versions</div>
                    <div class="addTranslations__addPanelClose"
                          (click)="closeAddVersions()">
                          <i class="bf-icon bf-icon-close-big"></i>
                    </div>

                    <input type="text"
                            placeholder="Search language..."
                            [(ngModel)]="localizationSearchQuery"/>

                    <div class="addTranslations__quickActions">
                        <a (click)="localizations.selectAll()">Select all</a>
                        |
                        <a (click)="localizations.deselectAll()">Deselect all</a>
                    </div>

                    <div class="addTranslations__localizationList">
                        <div class="addTranslations__localization"
                            *ngFor="let localization of localizations | filter:localizationSearchQuery: 'data.name'">
                            <label>
                                <ui-checkbox [(selected)]="localization.selected"></ui-checkbox>
                                <ui-flag size="tiny" [culture]="localization.data.cultureCode"></ui-flag>
                                {{ localization.data.name | ellipsisMiddle: 50 }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!--EDIT NEW VERSIONS (step 2)-->
            <div class="addTransaltions__step2"
                  *ngIf="step == 2" #step2>
                <table class="bfBlockTable">
                    <thead [ngStyle]="{ opacity : isEditingTranslations ? '0.15' : 1 }">
                        <tr>
                          <th class="language-column">Language</th>
                          <th *ngIf="hasTextsInOriginalTranslation" class="send-for-translation-column">Send for translation</th>
                          <th class="publish-url-column">Publish URL</th>
                          <th class="action-column"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let translation of newTranslations let i = index"
                        [ngClass]="{ 'bfBlockTable--invalid' : !translation.isValid, 'bfBlockTable__animate' :  newTranslations.length < 10  }"
                        [translationRow]="translation"
                        [translationRowShowSendForTranslation]="hasTextsInOriginalTranslation"
                        [translationRowValidation]="validate"
                        [translationRowOtherTranslations]="newTranslations"></tr>
                    </tbody>
                </table>
            </div>

            <!--EXISTING VERSIONS-->
            <div class="addTranslations__existing"
                  [ngClass]="{ 'addTranslations__existing--hiddenLeft' : step > 1 }">
                <div class="seperator">
                    <div class="seperator__text">Exisiting versions</div>
                </div>
                <table class="bfBlockTable">
                    <thead [ngStyle]="{ opacity : isEditingTranslations ? '0.15' : 1 }">
                        <tr>
                            <th style="width: 10%">Language</th>
                            <th style="width: 60px;text-align:center">Status</th>
                            <th>Publish URL</th>
                            <th style="width: 81px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [translationRow]="originalTranslation"
                            [translationRowValidation]="validate"
                            [translationRowIsOriginal]="true"
                            translationRowShowSendForTranslation="true"
                            [ngClass]="{ 'bfBlockTable--invalid' : !originalTranslation?.isValid  }"></tr>
                        <tr *ngFor="let existingTranslation of existingTranslations"
                            [translationRow]="existingTranslation"
                            [translationRowValidation]="validate"
                            translationRowShowSendForTranslation="true"
                            [ngClass]="{ 'bfBlockTable--invalid' : !existingTranslation.isValid  }"></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
