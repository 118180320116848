<div class="datePickerButton__container" [ngClass]="{'datePickerButton__container--pristine': pristine, 'datePickerButton__container--selecting': selecting, 'datePickerButton__container--isSpan': options.isSpan}">
    <div class="datePickerButton__button" (click)="showDatePicker()" #button>
        <div class="datePickerButton__buttonText">
            <i class="bf-icon bf-icon-calendar"></i>
            <span class="datePickerButton__placeholderDateText">
                <span class="datePickerButton__placeholderText" *ngIf="pristine">{{options.placeholder}}</span>
                <span class="datePickerButton__dateText" *ngIf="!pristine">{{durationText}}</span>
                <i *ngIf="options.allowEmpty && !pristine" class="datePickerButton__clean bf-icon bf-icon-remove" (click)="clear($event)"></i>
            </span>
        </div>
        <div class="datePickerButton__caret">
            <i class="bf-icon bf-icon-caret-down" aria-hidden="true"></i>
        </div>
    </div>
    <div class="datePickerButton__picker" *ngIf="selecting" #datePicker>
        <bfDatePicker
            [(ngModel)]="duration"
            [options]="options"
            (change)="emitDurationChangeEvent()"></bfDatePicker>
    </div>
</div>