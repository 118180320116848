import { Route } from '@angular/router';
import { VersionsComponent } from './views/versions/versions.component';

export const versionsRoutes: Route[] = [
    {
        path: 'editor/:accountId/:brandId/:id',
        children: [
            {
                path: 'versions',
                component: VersionsComponent,
            }
        ]
    }
];
