import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ellipsisMiddle',
    standalone: true
})
export class EllipsisMiddlePipe implements PipeTransform {
    transform(text: any, maxLength: any = 15) {
        if(text.length > maxLength){
            return text.substring(0, maxLength/2) + '...' + text.substring(text.length - (maxLength/2), text.length);
        }

        return text;
    }
}