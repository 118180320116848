import { Injectable, ComponentFactoryResolver }         from '@angular/core';
import { BFComponentContainer }                           from './bfComponent.container';
import { BFMaterial } from '../bfMaterial';

@Injectable({ providedIn: 'root' })
export class BFOverlayContainer extends BFComponentContainer {
    constructor(componentFactoryResolver: ComponentFactoryResolver,
        bFMaterial: BFMaterial) {
        super(componentFactoryResolver, bFMaterial);
    }
}
