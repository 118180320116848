import {
  BFBytesPipe,
  BFDialogComponent, BFDialogResponse,
  BFNotificationService
} from "../../../../libs/material";
import {Component, ViewChild} from "@angular/core";
import {NgFor, NgIf} from "@angular/common";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {UserService} from "shared/services/user.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {UserModel} from "shared/models/user.model";
import {FileModel} from "shared/models/file.model";
import {Observable} from "rxjs";
import {FileType} from "shared/enums/fileType.enum";
import {State} from "shared/enums/state.enum";
import {LandingPageModel} from "shared/models/landingPage.model";
import {UIModule} from "@bannerflow/ui";

@Component({
    styleUrls: ['./uploadDialog.component.scss'],
    selector: 'uploadDialog',
    templateUrl: './uploadDialog.component.html',
    standalone: true,
  imports: [BFDialogComponent, NgIf, NgFor, BFBytesPipe, EllipsisMiddlePipe, UIModule]
})

export class UploadDialogComponent {

    private callback: Function;
    public selectedFiles: any[] = [];
    private uploadUrl: string;
    public loading: boolean;
    public title: string;
    public multiple: boolean;
    private allowedFiles: string;
    public zipChosen: boolean;

    @ViewChild('fileInput', { static: true }) fileInputElement: any;

    constructor(private userService: UserService,
        private landingPageService: LandingPageService,
        private readonly notificationService: BFNotificationService) {
        this.userService.get().then((user: UserModel) => {
            this.uploadUrl = `api/landingpage/${user.accountSlug}/${user.brandId}/`;
        })
    }

    public initiate(multiple: boolean, allowedFiles: string, title: string): Promise<BFDialogResponse<FileModel[]>> {
        this.title = title;
        this.multiple = multiple;
        this.allowedFiles = allowedFiles;

        var promise = new Promise<BFDialogResponse<FileModel[]>>((resolve) => {
            this.callback = resolve;
        });

        return promise;
    }

    public cancel(): void {
        var response = new BFDialogResponse<FileModel[]>();
        response.cancel = true;

        this.callback(response);
    }

    private confirm(): void {

    }

    private removeFile(file: any): void {
        this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }

    private selectFiles(event: any): void {
        for (let key in event.target.files) {
            if (event.target.files.hasOwnProperty(key)) {
                this.selectedFiles.push(event.target.files[key]);
            }
        }

        if (this.selectedFiles.length === 1 &&
            this.selectedFiles[0].type === 'application/zip' || this.selectedFiles[0].type === 'application/x-zip-compressed') {
            this.zipChosen = true;
        } else {
            this.zipChosen = false;
        }
    }

    public upload(): void {
        this.loading = true;

        let xhr: XMLHttpRequest = new XMLHttpRequest();
        let formData = new FormData();

        this.selectedFiles.forEach((file: any) => {
            formData.append('file', file);
        });

        return Observable.create(observer => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('PUT', this.uploadUrl + 'uploadfile', true);
            xhr.send(formData);
        })
            .toPromise()
            .then((response) => {
                var files: FileModel[] = [];
                var invalidFiles = [];
                var dialogResponse: BFDialogResponse<FileModel[]> = new BFDialogResponse<FileModel[]>();

                for (var i = 0; i < response.data.length; i++) {
                    var newFile: FileModel = new FileModel().deserialize(response.data[i]);

                    if (newFile.type === FileType.Unknown) {
                        invalidFiles.push(newFile.name);
                        continue;
                    }

                    newFile.state = State.New;
                    files.push(newFile);
                }

                if (invalidFiles.length > 0) {
                    this.notificationService.show("Unsupported file type: "
                        + invalidFiles.join(', '), 'error', 'top', 8000);
                }

                dialogResponse.data = files;
                this.callback(dialogResponse);

            })
            .catch((response) => {
                this.notificationService.show("Could not upload file " + response, 'error', 'top', 6000);
                this.cancel();
            });

    }

    private migrate(): void {
        this.loading = true;

        this.landingPageService.get()
            .then((landingPage: LandingPageModel) => {
                let xhr: XMLHttpRequest = new XMLHttpRequest();
                let formData = new FormData();

                this.selectedFiles.forEach((file: any) => {
                    formData.append('file', file);
                });

                return Observable.create(observer => {
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                observer.next();
                                observer.complete();
                            } else {
                                observer.error(xhr.response);
                            }
                        }
                    };

                    xhr.open('PUT', this.uploadUrl + `importb1?landingPageId=${landingPage.id}`, true);
                    xhr.send(formData);
                })
                    .toPromise()
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((response) => {
                        console.error('Could not upload file', response);
                    });
            });
    }

}
