import { Directive, ElementRef, Input, Renderer2, OnDestroy, OnInit, Output, EventEmitter, ComponentRef } from '@angular/core';

import { BFMaterial } from '../../../bfMaterial';
import { BFComponentContainer } from '../../../containers/bfComponent.container';

import { BFMenuItem } from '../bfMenuItem';
import { BFMenuConfig } from '../bfMenuConfig';
import { BFMenuComponent } from '../list/bfMenu.list.component';
import { BFMenuDirective } from '../bfMenu.directive';

@Directive({
    selector: '[bfContextMenu]',
    exportAs: 'bfContextMenu', // the name of the variable to access the directive
    outputs: ['select'],
    standalone: true
})

export class BFContextMenuDirective extends BFMenuDirective {
    // @Input('bfContextMenu') public menuConfig: BFMenuConfig;
    @Output('bfContextMenuOpen') public menuOpen: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output('bfContextMenuClose') public menuClose: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    //Listeners
    private onRightClick: Function;
    private onBodyRightClick: Function;
    private closeTimeout: number;

    constructor(elementRef: ElementRef,
        Renderer2: Renderer2,
        componentContainer: BFComponentContainer,
        bfMaterial: BFMaterial) {

        super(elementRef, Renderer2, componentContainer, bfMaterial);
    }

    public ngOnInit(): void {
        this.onRightClick = this.Renderer2.listen(this.elementRef.nativeElement, 'contextmenu', (event: MouseEvent) => {
            event.preventDefault();

            // If menu is closed, open a new menu
            if (!this.opened) {
                this.open({ top: event.pageY, left: event.pageX });
                this.menuOpen.emit(event);
            }
            else {
                this.menuClose.emit(event);
                event.stopPropagation();
                this.subClose();

            }
        });
    }

    // Close that extends parent close
    private subClose(): void {
        this.close();

        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }

        if (this.onBodyRightClick) {
            this.onBodyRightClick();
        }
    }

    // Remove listeners
    public ngOnDestroy(): void {
        this.subClose();

        if (this.onRightClick) {
            this.onRightClick();
        }
    }

}