import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	private httpClient: HttpClient;

	constructor(private handler: HttpBackend) {
		this.httpClient = new HttpClient(handler);
	}

	public getPullRequests(): Observable<string[]> {
		return this.httpClient.get<Array<any>>(
			'https://sandbox-lp2.bannerflow.com/deployment-slots.json',
		);
	}
}
