import {TranslationModel} from "shared/models/translation.model";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AppService {
    public sideBarView: SideBarView = SideBarView.None;
    public selectedVersion: TranslationModel;

    constructor() {

    }

    public toggleTranslationSideBar(toggle: boolean): void {
         if (toggle) {
            this.sideBarView = SideBarView.Texts;
         }
         else {
            this.sideBarView = (this.sideBarView === SideBarView.Texts)
                ? SideBarView.None
                : SideBarView.Texts;
         }
    }

    public toggleFilesSideBar(toggle: boolean): void {
        if (toggle) {
            this.sideBarView = SideBarView.Files;
        }
        else {
            this.sideBarView = (this.sideBarView === SideBarView.Files)
                ? SideBarView.None
                : SideBarView.Files;
        }
    }

}

export enum SideBarView {
    None,
    Texts,
    Files,
}
