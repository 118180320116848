
import { Route } from "@angular/router";
import {TranslatorDashboardViewComponent} from "externalTranslation/views/dashboard/translatorDashboard.view.component";
import {TranslationMainViewComponent} from "externalTranslation/views/translationMain.view.component";
import {TranslationViewComponent} from "externalTranslation/views/translation/translation.view.component";
import {DesignViewComponent} from "design/views/design/design.view.component";

export const externalTranslationRoutes: Route[] = [
    {
        path: 'translator/:translatorId',
        component: TranslatorDashboardViewComponent,
    },
    {
        path: 'translation/:accountSlug/:brandId/:landingPageId/:translationId/:translatorId',
        component: TranslationMainViewComponent,
        children: [
            {
                path: '',
                component: TranslationViewComponent,
                children: [
                    {
                        path: '',
                        component: DesignViewComponent,
                    }
                ]
            }
        ]
    }
];
