// libs
import { Injectable } from '@angular/core';
import {TranslationModel} from "shared/models/translation.model";
import {ApiService} from "shared/services/api.service";


@Injectable({ providedIn: 'root' })
export class TranslationService {
    private readonly controller = "translation";

    constructor(private apiService: ApiService) { }

    public create(landingPageId: string, translations: TranslationModel[]): Promise<TranslationModel[]> {
        return new Promise((resolve: Function, reject: Function) => {
            let data = [];
            translations.forEach((t: TranslationModel) => {
                let insert = {
                    localizationId: t.localizationId,
                    name: t.name,
                    culture: t.culture,
                    cultureName: t.cultureName,
                    publishSlug: t.publishSlug,
                    fallbackUrl: t.fallbackUrl,
                    translator: t.translator,

                    messageToTranslator: t.translator ? t.messageToTranslator : null,
                    deadline: t.translator ? t.deadline : null,
                    closeOnDeadline: t.translator ? t.closeOnDeadline : false,
                    notifyOnDone: t.translator ? t.notifyOnDone : [],

                    // if the translation is assigned to a translator, set the state to "pending"
                    translationState: t.translationState
                };

                data.push(insert);
            });
            let action = `new?landingPageId=${landingPageId}`;
            this.apiService.post(this.controller, action, data)
                .then((response: any) => {
                    let newTranslations: TranslationModel[] = [];
                    response.forEach((t: any) => {
                        newTranslations.push(new TranslationModel().deserialize(t));
                    });

                    resolve(newTranslations);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    }

    public update(landingPageId: string, translation: TranslationModel, forceSendEmail?: boolean): Promise<TranslationModel> {

        let translationInsert = {
            id: translation.id,
            localizationId: translation.localizationId,
            name: translation.name,
            culture: translation.culture,
            publishSlug: translation.publishSlug,
            fallbackUrl: translation.fallbackUrl,
            translationState: translation.translationState,
            translator: translation.translator,

            deadline: translation.translator ? translation.deadline : null,
            closeOnDeadline: translation.translator ? translation.closeOnDeadline : false,
            notifyOnDone: translation.translator ? translation.notifyOnDone : [],
            messageToTranslator: translation.translator ? translation.messageToTranslator : null
        };

        let action = `update?landingPageId=${landingPageId}`;
        if (forceSendEmail) {
            action += `&forceSendemail=true`;
        }
        return new Promise<TranslationModel>((resolve: Function, reject: Function) => {

            this.apiService.post(this.controller, action, translationInsert)
                .then((translator: any) => {
                    let updatedTranslation = new TranslationModel().deserialize(translator);
                    resolve(updatedTranslation);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    public delete(landingPageId: string, translationId: string): Promise<void> {

        let action = `delete?landingPageId=${landingPageId}&translationId=${translationId}`;
        return new Promise<void>((resolve: Function, reject: Function) => {

            this.apiService.get(this.controller, action)
                .then(() => {
                    resolve();
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    public sendTranslationRequest(landingPageId: string, translationId: string): Promise<TranslationModel> {

        let action = `sendtranslation?landingPageId=${landingPageId}&translationId=${translationId}`;

        return new Promise<TranslationModel>((resolve: Function, reject: Function) => {
            this.apiService.get(this.controller, action)
                .then((data: any) => {
                    let translation = new TranslationModel().deserialize(data);
                    resolve(translation);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }
}
