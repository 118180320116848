import { Route } from '@angular/router';
import { PublishComponent } from './views/publish/publish.component';

export const publishRoutes: Route[] = [
    {
        path: 'editor/:accountId/:brandId/:id',
        children: [
            {
                path: 'publish',
                component: PublishComponent,
            }
        ]
    }
];
