// libs
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    Renderer2
} from '@angular/core';

import { BFComponentContainer, BFMaterial } from "../../../../../../libs/material/index";

// app
import { CodeEditorMode } from './codeEditor.mode';
import { CodeEditorUtils } from './codeEditor.utils';
import { DocumentService, Document } from '../../../../services/document.service';
import { TextPopOverComponent } from './textPopOver/textPopOver.component';

import CodeMirror from 'codemirror';
import {LandingPageService} from "shared/services/landingPage.service";
import {TextService} from "shared/services/text.service";
import {HotkeysService} from "shared/services/hotkeys.service";
import {LandingPageModel} from "shared/models/landingPage.model";
import {DialogAddon} from "code/views/code/components/codeEditor/addons/dialog/dialog.addon";
import {SearchAddon} from "code/views/code/components/codeEditor/addons/search/search.addon";
import {TextModel} from "shared/models/text.model";


@Component({
    selector: 'codeEditor',
    styleUrls: ['codeEditor.component.scss'],
    templateUrl: 'codeEditor.component.html',
    standalone: true
})
export class CodeEditorComponent implements OnInit {
    @Input('document')
    set setDocument(document: Document) {
        this.openDocument(document);
    }
    private document: Document;

    @ViewChild('codeEditor', { static: true }) codeEditorElementRef: ElementRef;
    @ViewChild('translateButton', { static: true }) translateButtonElementRef: ElementRef;

    private codeMirrorEditor: CodeMirror.Editor;
    private codeMirrorConfig: CodeMirror.EditorConfiguration;
    private codeEditorMode: CodeEditorMode;
    private currentLine: number;
    private textPopOver: any;

    constructor(private landingPageService: LandingPageService,
        private textService: TextService,
        private componentContainer: BFComponentContainer,
        private bfMaterial: BFMaterial,
        private Renderer2: Renderer2,
        private documentService: DocumentService,
        private hotkeysService: HotkeysService) {

        this.hotkeysService.bindKey(['command+l', 'ctrl+l'], () => {
            this.makeTextTranslatable();
        });
    }

    ngOnInit() {
        this.Renderer2.listen(this.translateButtonElementRef.nativeElement,
            'click',
            () => { this.makeTextTranslatable(); });
    }

    private onChange(): void {
        if (this.document.file.updateStringContent(this.codeMirrorEditor.getValue())) {
            this.landingPageService.toggleDirty(true);
        }

        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            this.textService.refreshTexts(this.document.file, landingPage.originalTranslation);
        });

    }

    private positionTranslateButton(): void {
        var doc: CodeMirror.Doc = this.codeMirrorEditor.getDoc();
        var selections = doc.listSelections();
        var selection = selections[0];

        if (selection.anchor.ch != selection.head.ch || selection.anchor.line != selection.head.line) {
            setTimeout(() => {
                var coords = CodeEditorUtils.GetLastSelectionPoint(this.codeMirrorEditor);
                this.translateButtonElementRef.nativeElement.style.top = (coords.y) + 'px';
                this.translateButtonElementRef.nativeElement.style.left = (coords.x + 30) + 'px';
            }, 1);
        }
    }

    private createCodeMirrorEditor(): void {
        this.codeEditorMode.init();

        this.codeMirrorConfig = {
            value: this.document.file.stringContent,
            smartIndent: true,
            theme: 'bf-dark',
            indentUnit: 4,
            smartSelector: true,
            lineNumbers: true,
            lineWrapping: false,
            styleActiveLine: true,
            dragDrop: false,
            matchTags: true,
            autoCloseBrackets: false,
            autoCloseTags: true,
            viewportMargin: 10,
            extraKeys: {
                "Ctrl-Space": "autocomplete",
                "Cmd-Space": "autocomplete",
                "Ctrl-F": "findPersistent",
                "Cmd-F": "findPersistent"
            }
        } as CodeMirror.EditorConfiguration;

        this.codeMirrorEditor = CodeMirror(this.codeEditorElementRef.nativeElement, this.codeMirrorConfig);

        this.codeMirrorEditor.on('change', () => {
            this.onChange();
            this.document.history = this.codeMirrorEditor.getDoc().getHistory();
        });

        this.codeMirrorEditor.on('scroll', (e: any) => {
            this.document.scrollPosition = this.codeMirrorEditor.getScrollInfo().top;

            this.positionTranslateButton();
        });

        this.codeMirrorEditor.on("cursorActivity", () => {
            var doc: CodeMirror.Doc = this.codeMirrorEditor.getDoc();
            var selections = doc.listSelections();
            var selection = selections[0];

            if (selection.anchor.ch === selection.head.ch && selection.anchor.line === selection.head.line) {
                this.translateButtonElementRef.nativeElement.classList.toggle('codeEditor__translateButton--visible', false);
            } else {
                this.translateButtonElementRef.nativeElement.classList.toggle('codeEditor__translateButton--visible', true)

                this.positionTranslateButton();
            }

            //Save selection on document
            this.document.selection = selection;
        });

        this.codeMirrorEditor.getWrapperElement().addEventListener('click', (event: MouseEvent) => {
            var element: HTMLElement = event.target as HTMLElement;

            if (element.className.indexOf('cm-smart-selector') != -1) {

                var positionInDocument = this.codeMirrorEditor.coordsChar({ left: event.clientX, top: event.clientY });
                this.currentLine = positionInDocument.line;

                var marker = this.codeMirrorEditor.getDoc().findMarksAt(positionInDocument)[0] as any;

                if (!marker)
                    return;

                var markerRef = marker.lines[0].markedSpans.filter(function (s) { return s.marker === marker })[0];

                if (markerRef) {

                    if (this.textPopOver) {
                        this.textPopOver.instance.close();
                    } else {
                        var key = $(marker.replacedWith).attr("id");
                        var markerPos = { from: markerRef.from, to: markerRef.to };

                        this.landingPageService.get().then((landingPage: LandingPageModel) => {
                            this.showTranslatePopOver(this.textService.getTextByKey(key, landingPage.originalTranslation), event);
                        });

                    }
                }
            }
        });

        //Setup addons
        DialogAddon.init();
        SearchAddon.init();
    }

    private makeTextTranslatable(): void {
        const doc = this.codeMirrorEditor.getDoc() as any;
        const selections = doc.listSelections();
        const selection = selections[0];
        var value = doc.getSelection();

        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            //Find any eventual translatable text references in the selected texts
            const foundTranslatableTexts = value.match(/[^@{]+(?=\})/g);

            if (foundTranslatableTexts) {
                foundTranslatableTexts.forEach(textId => {
                    const text = this.textService.getTextByKey(textId, landingPage.originalTranslation);
                    value = value.replace(`@{${textId}}`, text.value);
                });
            }

            var text = this.textService.addText(value, landingPage.originalTranslation);
            doc.replaceRange("@{" + text.key + "}", selection.head, selection.anchor, "api-before");

            //Deselect
            this.codeMirrorEditor.getDoc().setCursor(this.codeMirrorEditor.getDoc().getCursor());
        });
    }

    private showTranslatePopOver(text: any, event: MouseEvent): void {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            this.textPopOver = this.componentContainer.attach(TextPopOverComponent, this.bfMaterial.rootViewContainerRef);

            this.textPopOver.instance.initiate(text, event.target, landingPage.originalTranslation).then((editedText: TextModel) => {
                if (editedText) {
                    this.textService.setTextValue(text.key, editedText.value, landingPage.originalTranslation);

                    var line = this.codeMirrorEditor.getDoc().getLineHandle(this.currentLine);
                    var ch = line.text.length;

                    var doc = this.codeMirrorEditor.getDoc() as any;
                    doc.replaceRange('', { line: this.currentLine, ch: ch }, { line: this.currentLine, ch: ch + 1 }, 'api');

                } else if (editedText === null) {
                    //if editiedText is null, clear the translation block
                    var key = '@{' + text.key + '}';
                    var line = this.codeMirrorEditor.getDoc().getLineHandle(this.currentLine);
                    var index = line.text.indexOf(key);

                    if (index != -1) {
                        var doc = this.codeMirrorEditor.getDoc() as any;
                        doc.replaceRange(text.value, { line: this.currentLine, ch: index }, { line: this.currentLine, ch: index + key.length }, 'api');
                    }
                }

                this.componentContainer.detach(this.textPopOver);
                this.textPopOver = null;

                this.refreshEditor();
            });
        });
    }

    private openDocument(document: Document): void {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            //Make sure translation text service is initiated
            this.codeEditorMode = new CodeEditorMode(this.textService, landingPage.originalTranslation, () => {
                this.onChange();
            });

            document.file.stringContent = document.file.stringContent || '';
            this.document = document;

            if (!this.codeMirrorEditor)
                this.createCodeMirrorEditor();

            this.refreshEditor();
        });
    }

    public refreshEditor(): void {
        setTimeout(() => {
            this.codeMirrorEditor.operation(() => {
                this.codeMirrorEditor.getDoc().setValue(this.document.file.stringContent);


                //Set correct syntax highlight depending on file extension
                // @ts-ignore
              this.codeMirrorEditor.setOption('mode', { name: "bfmode", backdrop: CodeEditorMode.getModeFromFile(this.document.file) });
              // @ts-ignore
                this.codeMirrorEditor.setOption('smartSelector', () => { });

                this.codeMirrorEditor.setOption('mode', { name: "bfmode" });

                this.codeMirrorEditor.refresh();

                if (this.document.selection) {
                    this.codeMirrorEditor.focus();
                    this.codeMirrorEditor.getDoc().setSelection(this.document.selection.anchor,
                        this.document.selection.head);
                }

                if (this.document.scrollPosition) {
                    this.codeMirrorEditor.scrollTo(0, this.document.scrollPosition);
                }

                //If document has cached history, overrite the history in the current document
                //else clear history on document.
                if (this.document.history)
                    this.codeMirrorEditor.getDoc().setHistory(this.document.history);
                else
                    this.codeMirrorEditor.getDoc().clearHistory();
            });
        });
    }
}

