define([], function() {

  'use strict';

  return {
    contentChanged: "scribe:content-changed",
    legacyContentChanged: "content-changed",
    destroy: "scribe:destroy"
  };
});
