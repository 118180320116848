<div class="bfSelectList bfSelectList--{{ selectConfig.options.placement }}"
    [ngClass]="{ 'bfSelectList--bfMultiSelect' : selectConfig.options.multi,
                 'bfSelectList--searchable': selectConfig.options.searchable }"
    [ngStyle]="{ left: position.left + 'px', top: position.top + 'px' }">
    <div class="bfSelectList__list">

        <bfSearch
            [(search)]="selectConfig.selectItems"
            type="bfSelect"
            *ngIf="selectConfig.options.searchable"></bfSearch>

        <!--ITEMS-->
        <div class="bfSelectList__item"
             *ngFor="let item of selectConfig.selectItems"
             (click)="onSelect(item, $event)"
             [ngClass]="{ 'bfSelectList__item--seperator' : item.isSeperator, 'bfSelectList__item--disabled' : item.disabled, 'bfSelectList__item--selected' : item.selected, 'bfSelectList__item--hidden': item.hidden }">

            <!--CHECKBOX (MULTI)-->
            <div class="bfSelectList__checkbox"
                *ngIf="selectConfig.options.multi && !item.isSeperator" >
                <bfCheckbox [checked]="item.selected"></bfCheckbox>
            </div>

            <!--ICON-->
            <i class="bfSelectList__itemIcon bf-icon bf-icon-{{ item.icon }} bf-icon-fw"
                *ngIf="item.icon && !item.isSeperator"></i>

            <!--FLAG-->
            <ui-flag size="tiny" *ngIf="item.flag && !item.isSeperator"
                    [culture]="item.flag"></ui-flag>

            <!--TEXT-->
            <span class="bfSelectList__label"
                    *ngIf="!item.isSeperator">
                {{ item.label }}
                <span class="bfSelectList__description"
                *ngIf="item.description">{{ item.description }}</span>
            </span>
        </div>
    </div>
</div>
