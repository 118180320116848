<ng-container *ngIf="pullRequests$ | async as pullRequests">
  <ui-select
    class="branch"
    [width]="'100%'"
    [useTargetWidth]="true"
    [value]="selectedBranch"
    (selectedChange)="selectedChanged($event)">
    <ui-option value="Main"> Main </ui-option>
    <ui-option
      *ngFor="let prBranchName of pullRequests"
      [value]="prBranchName">
      {{ prBranchName }}
    </ui-option>
  </ui-select>
</ng-container>
