import {BFNotificationService} from "../../../../libs/material";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {CommonModule, NgFor, NgIf} from "@angular/common";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {LandingPageModel} from "shared/models/landingPage.model";
import {PublishProgressResponse, PublishService} from "shared/services/publish.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {TranslationModel} from "shared/models/translation.model";
import {PublishState} from "shared/enums/publishState.enum";
import {UIModule} from "@bannerflow/ui";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, from, map, Observable, of, startWith, Subject, takeUntil, tap } from "rxjs";
import { LandingPageStore } from '../../../landingPage.component.store';

@Component({
    styleUrls: ['./publish.component.scss'],
    selector: 'publish',
    templateUrl: './publish.component.html',
    standalone: true,
    imports: [ CommonModule, EllipsisMiddlePipe, UIModule ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishComponent implements OnInit, OnDestroy {

    private landingPage$: Observable<LandingPageModel>;
    private type$: Observable<'publish' | 'unpublish'>;
    public selectedTranslations$: Observable<TranslationModel[]>;
    private cnames$: Observable<any>;
    public numberOfSelectedVersions$: Observable<number>;
    public affectedTranslations$: Observable<TranslationModel[]>;
    public loading: boolean;
    private allSelected = false;
    public numberOfAffectedVersions: number;
    public publishingText: string;
    public publishingErrorText: string;
    private routeCommand = '../design';
    private translationsArray: TranslationModel[] = [];
    private unsubscribe$ = new Subject<void>();

    constructor(private publishService: PublishService,
        private landingPageStore: LandingPageStore,
        private landingPageService: LandingPageService,
        private notificationService: BFNotificationService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef
      ) { }

    @HostListener('document:keydown.escape', ['$event'])
    public handleEscKey(event: KeyboardEvent): void {
        this.cancel();
    }

    // todo: dont pass in untyped cnames. maybe just bake it into the publish service and dont even know about it here
    public ngOnInit(): void {
      this.landingPage$ = this.landingPageStore.loadLandingPage();
      this.cnames$ = this.landingPageStore.loadCnames();
      this.selectedTranslations$ = this.landingPageStore.loadSelectedTranslations();
      this.type$ = this.landingPageStore.loadType();
      this.emptySelection();

      combineLatest([this.landingPage$, this.selectedTranslations$]).pipe(
          map(([landingPage, selectedTranslations]) => {
              const originalTranslation = landingPage.originalTranslation;
              for (const st of selectedTranslations) {
                  if (originalTranslation.id === st.id) {
                      (originalTranslation as any).selected = true;
                  }
                  const t = landingPage.translations.find(t => t.id === st.id);
                  if (t) {
                      (t as any).selected = true;
                  }
              }
          }),
      ).subscribe();

      this.numberOfSelectedVersions$ = this.landingPage$.pipe(
        map( (landingPage) => {
            let numberOfSelected = landingPage.translations.reduce((sum, translation) => {
                if ((translation as any).selected) {
                    return sum + 1;
                }
                return sum;
                }, 0);
                if ((landingPage.originalTranslation as any).selected) {
                    numberOfSelected++;
                }
                return numberOfSelected;
          })
      );

      this.affectedTranslations$ = combineLatest([this.landingPage$, this.type$]).pipe(
        map( ([landingPage, type]) => {
            const affectedVersions: TranslationModel[] = [];
            if (type === 'publish') {
              if (landingPage.originalTranslation.publishState <= PublishState.Unpublished || landingPage.originalTranslation.publishState === PublishState.PublishedWithPendingUpdates) {
                  affectedVersions.push(landingPage.originalTranslation);
              }
              for (const t of landingPage.translations) {
                  if (t.publishState <= PublishState.Unpublished || t.publishState === PublishState.PublishedWithPendingUpdates) {
                      affectedVersions.push(t);
                  }
              }
            }
            else {
                if (landingPage.originalTranslation.publishState >= PublishState.Published) {
                    affectedVersions.push(landingPage.originalTranslation);
                }
                for (const t of landingPage.translations) {
                    if (t.publishState >= PublishState.Published) {
                        affectedVersions.push(t);
                    }
                }
            }
            return affectedVersions;
        }),
      );
    }

    public cancel(): void {
        return this.navigateTo(
          this.routeCommand,
          this.activatedRoute,
        );
    }

    public publish(): void {
      this.loading = true;

      this.landingPage$.pipe(
          takeUntil(this.unsubscribe$),
          map((landingPage) => {
              if ((landingPage.originalTranslation as any).selected)
                  this.translationsArray.push(landingPage.originalTranslation);
              (landingPage.translations || []).forEach((t: any) => {
                  if (t.selected) this.translationsArray.push(t);
              });

              this.publishingText = `0 of ${this.translationsArray.length} versions published`;

              const callback = (queueLeft: number, queueTotal: number, errorCount: number) => {
                  this.publishingText = `${queueLeft} of ${queueTotal} versions published. ${errorCount} versions failed.`;
                  this.cdr.markForCheck();
              };

              this.publishService
                  .publish(landingPage, this.translationsArray, callback, callback)
                  .then((response: any) => {
                      this.handleCompletedRequest(response, this.translationsArray);
                      this.loading = false;
                      return this.navigateTo(
                        this.routeCommand,
                        this.activatedRoute,
                      );
                  })
                  .catch((response) => {
                      this.handleCompletedRequest(response, this.translationsArray);
                      this.loading = false;
                  });
          })
      ).subscribe();
  }


  public unpublish(): void {
    this.loading = true;

    this.landingPage$.pipe(
        takeUntil(this.unsubscribe$),
        map((landingPage) => {
            let translations: TranslationModel[] = [];
            if ((landingPage.originalTranslation as any).selected)
                translations.push(landingPage.originalTranslation);
            (landingPage.translations || []).forEach((t: any) => {
                if (t.selected) translations.push(t);
            });

            this.publishingText = 'Unpublishing landing page...';

            const callback = (queueLeft: number, queueTotal: number, errorCount: number) => {
                this.publishingText = `${queueLeft} of ${queueTotal} versions unpublished. ${errorCount} versions failed.`;
                this.cdr.markForCheck();
            };

            this.publishService
                .unpublish(landingPage, translations, callback, callback)
                .then((response: any) => {
                    this.handleCompletedRequest(response, translations);
                    this.loading = false;
                    return this.navigateTo(
                      this.routeCommand,
                      this.activatedRoute,
                    );
                })
                .catch((response) => {
                    this.handleCompletedRequest(response, translations);
                    this.loading = false;
                });
        })
    ).subscribe();
}

    private readonly handleCompletedRequest = (
        response: PublishProgressResponse,
        processedTranslations: TranslationModel[]
    ):void => {
        map((type) => {
            const failed: string[] = [];
            for (const index in response.failedTranslations) {
                const translation = processedTranslations.find(t => t.id === response.failedTranslations[index].translationId);
                failed.push(`'${translation.name}'`); // - ${response.failedTranslations[index].error}
            }

            if (failed.length) {
                const msg = `Failed to ${type} ${failed.join(' | ')}`;
                this.notificationService.show(msg, "error", "top", undefined, 'alert');
            }
            else {
                let successfuls: string[] = [];
                response.successfulTranslationIds.forEach((id) => {
                    const translation = processedTranslations.find(t => t.id === id);
                    successfuls.push(translation.name);
                });

                if (successfuls.length) {
                    const msg = `${successfuls.join(' | ')} have been ${type}ed`;
                    this.notificationService.show(msg, "success", "top", undefined, 'finished');
                }
            }
            this.landingPage$ = from(this.landingPageService.get());
        })
    };

    public toggleSelect(): void {
        this.landingPage$.pipe(
            map( (landingPage) => {
                this.allSelected = !this.allSelected;
                (landingPage.originalTranslation as any).selected = this.allSelected;
                (landingPage.translations || []).forEach((t: any) => {
                    t.selected = this.allSelected;
                });
            })
        );
    }

    private emptySelection(): void {
        this.landingPage$.pipe(
            map( (landingPage) => {
                this.allSelected = false;
                (landingPage.originalTranslation as any).selected = false;
                (landingPage.translations || []).forEach((t: any) => {
                    t.selected = false;
                });
            })
        );
    }

    private isNullOrEmpty(text: string): boolean {
        // uses == instead of === on purpose, this check should be as sensitive as possible
        return text == null || text == '';
    }

    public validate(): Observable<boolean> {
        return this.landingPage$.pipe(
            map( (landingPage) => {
                let anySlugIsEmpty = this.isNullOrEmpty(landingPage.originalTranslation.publishSlug);

                if (landingPage.translations && !anySlugIsEmpty) {
                    landingPage.translations.forEach((t) => {
                        if (this.isNullOrEmpty(t.publishSlug)) anySlugIsEmpty = true;
                    });
                }

                return !anySlugIsEmpty;
            })
        );

    }

    public getUrl(translation: TranslationModel): Observable<string> {
      return combineLatest([this.landingPage$, this.cnames$]).pipe(
          map(([landingPage, cnames]) => {
              let url = this.publishService.getCname(
                translation.localizationId, landingPage.accountSlug, landingPage.brandId, cnames);

              return (`${url}${translation.publishSlug}/index.html`).toLowerCase();
          })
      );
    }

    public navigateTo(
      routeCommand: string,
      activatedRoute: ActivatedRoute,
    ): any {
      return this.router.navigate([routeCommand], {
        relativeTo: activatedRoute,
      });
    }

    public ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
}
