// libs
import { Component, Input, ViewChild } from '@angular/core';
import {FileTabsComponent} from "code/views/code/components/fileTabs/fileTabs.component";
import {NgClass, NgIf, NgStyle} from "@angular/common";
import {FileBrowserComponent} from "code/views/code/components/fileBrowser/fileBrowser.component";
import {CodeEditorComponent} from "code/views/code/components/codeEditor/codeEditor.component";
import {LandingPageModel} from "shared/models/landingPage.model";
import {FileType} from "shared/enums/fileType.enum";
import {LandingPageService} from "shared/services/landingPage.service";
import {Themes, ThemeService} from "shared/services/theme.service";
import {TextService} from "shared/services/text.service";
import {CodeService} from "code/services/code.service";
import {AppService} from "../../../app.service";
import {BFNotificationService} from "../../../../libs/material";
import {DocumentService, Document} from "code/services/document.service";
import {FileModel} from "shared/models/file.model";

// app

@Component({
    selector: 'codeView',
    styleUrls: ['code.view.component.scss'],
    templateUrl: 'code.view.component.html',
    standalone: true,
    imports: [NgClass, NgIf, FileBrowserComponent, FileTabsComponent, CodeEditorComponent, NgStyle]
})
export class CodeViewComponent {
    public landingPage: LandingPageModel;
    protected selectedFileUrl: string; //Only to show translated image
    private loading: boolean;
    protected fileTypeEnum = FileType;
    protected activeDocument: Document;
    private activeDocumentSubscription: any;

    @ViewChild('codeEditor', { static: true }) codeEditor: CodeEditorComponent;

    constructor(private landingPageService: LandingPageService,
        private themeService: ThemeService,
        private textService: TextService,
        private codeService: CodeService,
        public appService: AppService,
        private notificationService: BFNotificationService,
        private documentService: DocumentService) {

        this.themeService.setTheme(Themes.Dark);
        this.codeService.onFileNameChanged.subscribe((file: FileModel) => {
            // codeEditor won't exist for files like images
            if (this.codeEditor) {
                this.codeEditor.refreshEditor();
            }
        });

        this.activeDocumentSubscription = this.documentService.activeDocumentChange.subscribe((document: Document) => {
            this.activeDocument = document;
            this.handleSelectedFile();
        });
    }

    private ngOnInit() {
        //Close all open notifications
        this.notificationService.close();
        this.appService.toggleFilesSideBar(true);
        this.activeDocument = this.documentService.activeDocument;
        this.landingPageService
            .get()
            .then((landingPage: LandingPageModel) => {
                this.landingPage = landingPage;

                //Force current translation to be original translation in code view
                //this.textService.setCurrentTranslation(landingPage.originalTranslation);

                if (!this.activeDocument)
                    this.documentService.openFile(this.landingPage.indexFile);

                this.handleSelectedFile();
            })
            .catch(response => {
                console.error(`Could not load the Landing page!`, response);
            });
    }

    private handleSelectedFile(): void {
        if (!this.activeDocument)
            return;

        let translatedResource = this.textService.getOriginalResource(this.activeDocument.file.id);

        //To show translated images
        this.selectedFileUrl = translatedResource ? translatedResource.url : this.activeDocument.file.url;
    }

    ngOnDestroy() {
        if (this.activeDocumentSubscription) {
            this.activeDocumentSubscription.unsubscribe();
        }
    }
}
