import {DocumentService, Document} from "code/services/document.service";
import {State} from "shared/enums/state.enum";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {Component} from "@angular/core";

@Component({
    styleUrls: ['fileTabs.component.scss'],
    selector: 'fileTabs',
    templateUrl: 'fileTabs.component.html',
    standalone: true,
    imports: [NgFor, NgClass, NgIf]
})

export class FileTabsComponent {
    private activeDocumentSubscription: any;
    private activeTab: Document;
    public tabs: Document[];
    private State = State;

    constructor(private documentService: DocumentService) {
        this.activeDocumentSubscription = this.documentService.activeDocumentChange.subscribe((document: Document) => {
            this.tabs = this.documentService.openDocuments;
            this.activeTab = this.documentService.activeDocument;
        });
    }

    private selectTab(tab: Document):void {
        this.documentService.setActiveDocument(tab);
    }

    private closeTab(tab: Document):void {
        this.documentService.closeDocument(tab);
    }

    ngOnInit(){
        this.tabs = this.documentService.openDocuments;
        this.activeTab = this.documentService.activeDocument;
    }

    ngOnDestroy(){
        if(this.activeDocumentSubscription){
            this.activeDocumentSubscription.unsubscribe();
        }
    }
}
