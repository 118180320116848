import {EventEmitter, Injectable, OnDestroy} from "@angular/core";
import {BFDialogContainer, BFDialogResponse, BFMaterial} from "../../../../libs/material";
import {FileModel} from "shared/models/file.model";
import {UploadDialogComponent} from "shared/components/uploadDialog/uploadDialog.component";

export interface FromArtboardShowEvent {
    id: string;
    element: HTMLElement;
}

@Injectable({ providedIn: 'root' })
export class UploadDialogService implements OnDestroy {
    public fromArtboardShow = new EventEmitter<FromArtboardShowEvent>();

    constructor(private dialogContainer: BFDialogContainer,
        private bfMaterial: BFMaterial) {
    }

    public show(multiple: boolean = true, allowedFiles: string = '*', title: string = 'Upload files', type: 'artboard' | 'sidebar' = 'sidebar', id?: string, element?: HTMLElement): Promise<BFDialogResponse<FileModel[]>> {
        if (type === 'artboard' && id && element) {
            this.fromArtboardShow.emit({
                id,
                element,
            });
        }

        var uploadDialog = this.dialogContainer.attach(UploadDialogComponent, this.bfMaterial.rootViewContainerRef);

        var promise = new Promise<BFDialogResponse<FileModel[]>>((resolve) => {
            uploadDialog.instance.initiate(multiple, allowedFiles, title).then((response: BFDialogResponse<FileModel[]>) => {
                resolve(response);
                this.dialogContainer.detach(uploadDialog);
            });
        });

        return promise;
    }

    ngOnDestroy() {

    }

}
