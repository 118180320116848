import 'codemirror';
declare var CodeMirror : any;

export class DialogAddon {

  constructor(){  }

  public static init():void{
    this.defineOpenDialog();
    this.defineOpenConfirm();
    this.defineOpenNotification();
  }

  private static dialogDiv(cm, template, bottom) : any{
    var wrap = cm.getWrapperElement();
    var dialog;
    dialog = wrap.appendChild(document.createElement("div"));

    if (bottom)
      dialog.className = "CodeMirror-dialog CodeMirror-dialog-bottom";
    else
      dialog.className = "CodeMirror-dialog CodeMirror-dialog-top";

    if (typeof template == "string") {
      dialog.innerHTML = template;
    } else { // Assuming it's a detached DOM element.
      dialog.appendChild(template);
    }

    return dialog;
  }

  private static closeNotification(cm, newVal) : void {
    if (cm.state.currentNotificationClose)
      cm.state.currentNotificationClose();
    cm.state.currentNotificationClose = newVal;
  }


  private static defineOpenDialog():void{
    var self = this;
    CodeMirror.defineExtension("openDialog", function(template, callback, options) {
      if (!options) options = {};

      self.closeNotification(this, null);

      var dialog = self.dialogDiv(this, template, options.bottom);
      var closed = false, me = this;
      function close(newVal: any) {

        if (typeof newVal == 'string') {
          inp.value = newVal;
        } else {
          if (closed) return;

          closed = true;
          dialog.parentNode.removeChild(dialog);
          me.focus();

          if (options.onClose) options.onClose(dialog);
        }

      }

      var inp = dialog.getElementsByTagName("input")[0], button;
      if (inp) {
        inp.focus();

        if (options.value) {
          inp.value = options.value;
          if (options.selectValueOnOpen !== false) {
            inp.select();
          }
        }

        if (options.onInput)
          CodeMirror.on(inp, "input", function(e) { options.onInput(e, inp.value, close);});
        if (options.onKeyUp)
          CodeMirror.on(inp, "keyup", function(e) {options.onKeyUp(e, inp.value, close);});

        CodeMirror.on(inp, "keydown", function(e) {
          if (options && options.onKeyDown && options.onKeyDown(e, inp.value, close)) { return; }
          if (e.keyCode == 27) {
            inp.blur();
            CodeMirror.e_stop(e);
            close(null);
          }
          if (e.keyCode == 13){
             CodeMirror.e_stop(e);
             callback(inp.value, e);
          }
        });

        //if (options.closeOnBlur !== false) CodeMirror.on(inp, "blur", close);

      } else if (button = dialog.getElementsByTagName("button")[0]) {
        CodeMirror.on(button, "click", function() {
          close(null);
          me.focus();
        });

        if (options.closeOnBlur !== false) CodeMirror.on(button, "blur", close);

        button.focus();
      }
      return close;
    });

  }

  private static defineOpenConfirm():void{
    var self = this;
    CodeMirror.defineExtension("openConfirm", function(template, callbacks, options) {
        self.closeNotification(this, null);
        var dialog = self.dialogDiv(this, template, options && options.bottom);
        var buttons = dialog.getElementsByTagName("button");
        var closed = false, me = this, blurring = 1;
        function close() {
          if (closed) return;
          closed = true;
          dialog.parentNode.removeChild(dialog);
          me.focus();
        }
        buttons[0].focus();
        for (var i = 0; i < buttons.length; ++i) {
          var b = buttons[i];
          (function(callback) {
            CodeMirror.on(b, "click", function(e) {
              CodeMirror.e_preventDefault(e);
              close();
              if (callback) callback(me);
            });
          })(callbacks[i]);
          CodeMirror.on(b, "blur", function() {
            --blurring;
            setTimeout(function() { if (blurring <= 0) close(); }, 200);
          });
          CodeMirror.on(b, "focus", function() { ++blurring; });
        }
      });
  }

  private static defineOpenNotification():void{
    var self = this;
    CodeMirror.defineExtension("openNotification", function(template, options) {
        self.closeNotification(this, close);
        var dialog = self.dialogDiv(this, template, options && options.bottom);
        var closed = false, doneTimer;
        var duration = options && typeof options.duration !== "undefined" ? options.duration : 5000;

        function close() {
          if (closed) return;
          closed = true;
          clearTimeout(doneTimer);
          dialog.parentNode.removeChild(dialog);
        }

        CodeMirror.on(dialog, 'click', function(e) {
          CodeMirror.e_preventDefault(e);
          close();
        });

        if (duration)
          doneTimer = setTimeout(close, duration);

        return close;
      });
  }



};
