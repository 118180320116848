// libs
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import {
  BFDialogResponse,
  BFTooltipContainer, BFTooltipDirective
} from "../../../../../../libs/material/index";
import {
  FileBrowserFileComponent
} from "code/views/code/components/fileBrowser/fileBrowserFile/fileBrowserFile.component";
import {
  FileBrowserFolderComponent
} from "code/views/code/components/fileBrowser/fileBrowserFolder/fileBrowserFolder.component";
import {FolderModel} from "shared/models/folder.model";
import {FileModel} from "shared/models/file.model";
import {LandingPageModel} from "shared/models/landingPage.model";
import {CodeService} from "code/services/code.service";
import {UserService} from "shared/services/user.service";
import {UploadDialogService} from "shared/components/uploadDialog/uploadDialog.service";
import {LandingPageService} from "shared/services/landingPage.service";

@Component({
    styleUrls: ['fileBrowser.component.scss'],
    selector: 'fileBrowser',
    templateUrl: 'fileBrowser.component.html',
    standalone: true,
    imports: [BFTooltipDirective, FileBrowserFileComponent, FileBrowserFolderComponent]
})

export class FileBrowserComponent {
    @Input() public rootFolder: FolderModel;
    @Input() public indexFile: FileModel;
    @Output() public fileSelect: EventEmitter<FileModel> = new EventEmitter<FileModel>();
    @ViewChild('rootFolderComponent', { static: true }) private rootFolderComponent: FileBrowserFolderComponent;
    public landingPage: LandingPageModel;
    public downloadZipUrl: string;

    constructor(private codeService: CodeService,
        private userService: UserService,
        private uploadDialogService: UploadDialogService,
        private landingPageService: LandingPageService,
        private tooltipContainer: BFTooltipContainer) {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            this.landingPage = landingPage;
            this.downloadZipUrl = `api/landingpage/${landingPage.accountSlug}/${landingPage.brandId}/downloadfiles/${landingPage.id}`;
        });
    }

    public uploadFiles(): void {
        //Hide all tooltips
        this.tooltipContainer.clear();

        this.uploadDialogService.show().then((response: BFDialogResponse<FileModel[]>) => {
            if (!response.cancel) {
                this.codeService.addFiles(this.rootFolder, response.data);
            }
        });
    }

    public addFile() {
        this.rootFolderComponent.addFile();
    }

    public addFolder() {
        this.rootFolderComponent.addFolder();
    }
}
