// libs
import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
    ViewChild,
    AfterViewInit,
    Host
} from '@angular/core';
import {
  BFMenuItem,
  BFMenuDirective,
  BFContextMenuDirective,
  BFMenuConfig,
  BFInlineEditDirective,
  BFConfirmDialogService, BFOrderByPipe
} from '../../../../../../../libs/material/index';
import {NgClass, NgFor, NgIf} from "@angular/common";
import {
  FileBrowserFileComponent
} from "code/views/code/components/fileBrowser/fileBrowserFile/fileBrowserFile.component";
import {FileBrowserItem} from "code/views/code/components/fileBrowser/fileBrowserItem/fileBrowserItem";
import {CodeService} from "code/services/code.service";
import {DocumentService} from "code/services/document.service";
import {FolderModel} from "shared/models/folder.model";
import {State} from "shared/enums/state.enum";

@Component({
    selector: 'fileBrowserFolder',
    styleUrls: ['fileBrowserFolder.component.scss'],
    templateUrl: 'fileBrowserFolder.component.html',
    inputs: ['item', 'depth', 'parentFolder'],
    outputs: ['fileSelect', 'nameChange'],
    standalone: true,
    imports: [NgIf, NgClass, BFContextMenuDirective, BFInlineEditDirective, BFMenuDirective, NgFor, FileBrowserFileComponent, BFOrderByPipe]
})
export class FileBrowserFolderComponent extends FileBrowserItem implements AfterViewInit {
    @Input() public selectHandler: any;
    @Input() public collapsed = false;

    @ViewChild('bfInlineEditReference', { static: true }) public inlineEditDirective: BFInlineEditDirective;

    constructor(
        private readonly _codeService: CodeService,
        private readonly _confirmDialogService: BFConfirmDialogService,
        private readonly _documentService: DocumentService) {

        super(_codeService, _confirmDialogService, _documentService);
    }

    ngAfterViewInit() {
        this.config.menuItems.unshift(new BFMenuItem('New folder', (item, event) => { this.addFolder() }))
        this.config.menuItems.unshift(new BFMenuItem('New file', (item, event) => { this.addFile() }))

        //Only do this if the item is not the rootFolder or index.html
        if (this.inlineEditDirective) {
            this.initFileItem(this.inlineEditDirective);
        }
    }

    public addFolder($event?: any) {
        this.collapsed = false;
        let newFolder = new FolderModel();
        newFolder.name = "";
        newFolder.state = State.New;

        (this.item as FolderModel).folders.push(newFolder);

        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
    }

    public addFile($event?: any) {
        this.collapsed = false;

        this.codeService.addFile(this.item as FolderModel, null);

        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
    }

    public toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }
}
