<bfDialog (onClose)="cancel()">
    <ui-loader *ngIf="loading"></ui-loader>
    <h3>{{ text }}</h3>
    <p>{{ subText }}</p>
    <div class="bfDialog__buttons">
        <ui-button
            id="interaction-cancel-dialog-landingpage-btn"
            type="default"
            (click)="cancel()"
            [text]="cancelText">
        </ui-button>
        <ui-button
            id="interaction-confirm-dialog-landingpage-btn"
            type="primary"
            (click)="confirm()"
            [text]="confirmText">
        </ui-button>
    </div>
</bfDialog>
