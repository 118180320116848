// libs
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BFNotificationService } from '../../../../libs/material/index';
import {
  TranslationSidebarComponent
} from "design/views/design/components/translationSidebar/translationSidebar.component";
import {NgClass, NgIf} from "@angular/common";
import {LandingPageService} from "shared/services/landingPage.service";
import {Themes, ThemeService} from "shared/services/theme.service";
import {AppService} from "../../../app.service";
import {TextService} from "shared/services/text.service";
import {LandingPageModel} from "shared/models/landingPage.model";
import {DesignEditorComponent} from "design/views/design/components/designEditor/designEditor.component";

@Component({
    selector: 'designView',
    styleUrls: ['design.view.component.scss'],
    templateUrl: 'design.view.component.html',
    standalone: true,
    imports: [NgClass, NgIf, TranslationSidebarComponent, DesignEditorComponent]
})
export class DesignViewComponent implements OnInit {
    @Output() onInitialized = new EventEmitter<boolean>();
    private initialized = false;

    constructor(private readonly router: Router,
        private readonly landingPageService: LandingPageService,
        private readonly themeService: ThemeService,
        public appService: AppService,
        private readonly textService: TextService,
        private readonly notificationService: BFNotificationService,
        private readonly activatedRoute: ActivatedRoute) {
        this.themeService.setTheme(Themes.Default);
        // select version if it was specified as a param
        this.activatedRoute.params.subscribe((params: any) => {
            if (params.translationId) {
                // no error handling (catch) since the service will handle that
                this.landingPageService.get().then((landingPage: LandingPageModel) => {
                    this.setCurrentVersion(params.translationId, landingPage);
                });
            }
            else {
                if (!this.initialized) {
                    this.onInitialized.emit(true);
                }
            }
        });
    }

    public ngOnInit() {

        // show sidebar
        this.appService.toggleTranslationSideBar(true);


        // notify if there are unsaved changes
        if (this.landingPageService.dirty
            && this.landingPageService.dirtyPreview) {
            this.notificationService.show(
                'You have unsaved changes.<br/>Save your changes to see them on canvas in Design view.', 'warning', 'bottom', undefined, 'finished');
        }
    }

    private setCurrentVersion(translationId: string, landingPage: LandingPageModel) {
        let allTranslations = [
            landingPage.originalTranslation,
            ...(landingPage.translations || [])
        ];
        let translation = allTranslations.find(t => t.id === translationId);
        this.textService.setCurrentTranslation(translation).then(() => {
            if (!this.initialized) {
                this.onInitialized.emit(true);
            }
        });
    }
}
