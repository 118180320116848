<div class="fileBrowser">
    <div class="fileBrowser__header">
        <div class="fileBrowser__headerName">
            {{ landingPage ? landingPage.name : '' }}
        </div>
        <div class="fileBrowser__buttons">
            <i class="bf-icon bf-icon-fw bf-icon-plus-smallfatty" 
               (click)="addFile()"
               bfTooltip="Create a new file"></i>
            <i class="bf-icon bf-icon-fw bf-icon-upload" 
               (click)="uploadFiles()"
               bfTooltip="Upload any file(s)"></i>
            <a class="bf-icon bf-icon-fw bf-icon-download"
               bfTooltip="Download all files as ZIP"
               [href]="downloadZipUrl"
               target="_blank"></a>
        </div>
    </div>

    <div class="fileBrowser__scrollView">
        <fileBrowserFile [item]="indexFile"
                         [depth]="0"
                         [locked]="true"></fileBrowserFile>

        <fileBrowserFolder #rootFolderComponent
                           [item]="rootFolder"
                           [depth]="0"></fileBrowserFolder>
    </div>
</div>





