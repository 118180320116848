import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { AppRoutes, routes } from './app.routes';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BFMaterialModule } from '../libs/material';
import { authHttpInterceptorFn } from '@auth0/auth0-angular'
import { TranslatorService } from "externalTranslation/translator.service";
import { DesignService } from "design/design.service";
import { CodeService } from "code/services/code.service";
import { DocumentService } from "code/services/document.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {provideSentinelService, withLoggerConfig, withNewRelic} from "@bannerflow/sentinel";


export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
  provideAuth0({
    domain: environment.LOGIN_URL,
    clientId: environment.CLIENT_ID,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/${window.location.search}`,
      audience: 'https://bannerflow.com/resources/',
      scope: 'openid profile email offline_access bannerflow',
    },
    httpInterceptor: {
      allowedList: [`${environment.API_URL}/*`]
    },
    useRefreshTokens: true
  }), provideSentinelService(withNewRelic({
        enabled: environment.NAME !== 'local',
        licenseKey: environment.NEW_RELIC_LICENSE_KEY,
        applicationId: environment.NEW_RELIC_APPLICATION_ID,
        accountId: environment.NEW_RELIC_ACCOUNT_ID,
        trustKey: environment.NEW_RELIC_TRUST_KEY,
        agentId: environment.NEW_RELIC_APPLICATION_ID,
        origins: [environment.API_URL],
        applicationVersion: environment.VERSION,
        releaseName: environment.RELEASE_NAME,
        releaseId: environment.VERSION
    }),withLoggerConfig({
      level: 'debug',
      production: environment.NAME === 'production',
      sandbox: environment.NAME === 'sandbox',
      stage: environment.NAME
    })),
  importProvidersFrom(BrowserModule, FormsModule, AppRoutes, BFMaterialModule, BrowserAnimationsModule),
    DesignService, CodeService, DocumentService, AppService, TranslatorService, provideHttpClient(withInterceptors([authHttpInterceptorFn]))
  ]
};
