import {BFMenuConfig, BFMenuConfigOptions, BFMenuItem} from "../../../../../libs/material";
import {DesignService} from "design/design.service";
import {TextService} from "shared/services/text.service";


export class PickSizeComponent {
    public sizeMenuConfig: BFMenuConfig;

    constructor(
        public designService: DesignService,
        public textService: TextService) {

        this.buildSizeMenu();
    }

    public buildSizeMenu(): void {
        let sizes = new Array<BFMenuItem>();

        for (let size of this.designService.artboardSizes) {
            sizes.push(new BFMenuItem(
                size.name,
                () => {
                    this.designService.setSize(size);

                    // make sure the iframe is reloaded so startup scripts run again
                    this.textService.setCurrentTranslation(this.textService.currentTranslation);
                }
            ));

            if (size.name === '1920px') {
                sizes.push(new BFMenuItem('', null, true));
            }
        }

        this.sizeMenuConfig = new BFMenuConfig(
            sizes,
            new BFMenuConfigOptions('bottom', { top: -2 }, true)
        );
    }
}
