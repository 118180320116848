import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'bfEllipsis',
    standalone: true
})
export class BFEllipsisPipe implements PipeTransform {

    /**
     * Ellispsis a string cutting away the end if exeeding maxlength
     * @param text string to be ellipsed
     * @param maxLength what is the limit before cutting. Default is 15.
     */
    public static ellipsis(text: any, maxLength: any = 15): string {
        // Make sure we have something to cut
        if (!text || !text.toString) {
            return '';
        }
        text = text.toString();

        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }

        return text;
    }

    /**
     * Ellispsis a string cutting away the middle if exeeding maxlength
     * @param text string to be ellipsed
     * @param maxLength what is the limit before cutting. Default is 15.
     */
    public static ellipsisMiddle(text: any, maxLength: any = 15): string {
        // Make sure we have something to cut
        if (!text || !text.toString) {
            return '';
        }
        text = text.toString();

        if (text.length > maxLength) {
            return text.substring(0, maxLength / 2) + '...' + text.substring(text.length - (maxLength / 2), text.length);
        }

        return text;
    }

    /**
     * Ellispsis a string
     * @param text string to be ellipsed
     * @param maxLength what is the limit before cutting. Default is 15.
     * @param cutMiddle Pass true if middle should be cut instead of the end
     */
    public transform(text: any, maxLength: any = 15, cutMiddle?: boolean): string {
        // which ellipsis should be used
        const method: any = cutMiddle ? BFEllipsisPipe.ellipsisMiddle : BFEllipsisPipe.ellipsis;
        return method(text, maxLength);
    }
}
