import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { BFSelectConfig } from './bfSelectConfig';
import { BFSelectDirective } from './bfSelect.directive';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'bfSelect',
    templateUrl: './bfSelect.component.html',
    styleUrls: ['./bfSelect.component.scss'],
    standalone: true,
  imports: [NgClass, BFSelectDirective, NgFor, NgIf, UIModule]
})

export class BFSelectComponent {
    @Input('config') selectConfig: BFSelectConfig;
    @ViewChild('bfSelectDirective', { static: true }) bfSelectDirective: BFSelectDirective;

    @Output('selectedChange') selectedChange: EventEmitter<any> = new EventEmitter<any>();
    @Input('selected')
    get selected() {
        return this._selected;
    }
    set selected(val) {
        this._selected = val;
    }

    private _selected: any = null;
    label: string | undefined;
    flag: string | null;
    icon: string | null;
    description: string | null;

    private changeTimeout: any;

    constructor(private elementRef: ElementRef) { }

    public ngOnChanges() {
        this.changeTimeout = setTimeout(() => {
            this.selectDirectiveChange();
        }, 10);
    }

    public selectDirectiveChange(): void {

        // Set label
        if (!this.bfSelectDirective.selectedItems || !this.bfSelectDirective.selectedItems.length) {
            this.label = this.selectConfig.options.placeholder;
            this.flag = null;
            this.icon = null;
        }
        else if (this.bfSelectDirective.selectedItems && this.bfSelectDirective.selectedItems.length) {
            if (this.bfSelectDirective.selectedItems.length === 1 && this.bfSelectDirective.selectedItems[0]) {
                this.label = this.bfSelectDirective.selectedItems[0].label;
                this.flag = this.bfSelectDirective.selectedItems[0].flag;
                this.icon = this.bfSelectDirective.selectedItems[0].icon;
            }
            else if (this.bfSelectDirective.selectedItems.length > 1) {
                this.label = `${this.bfSelectDirective.selectedItems.length} of ${this.selectConfig.selectItems.length} selected`;
                this.flag = null;
                this.icon = null;
            }
        }

        // Notify listeners
        this.selectedChange.emit(this.selected);
    }

    public clear(event: MouseEvent): void {
        event.stopPropagation();
        this.bfSelectDirective.clear();
        this.selectDirectiveChange();
    }

    public ngOnInit() {
        if (this.selectConfig.options.fullWidth) {
            this.elementRef.nativeElement.style.display = 'block';
            this.elementRef.nativeElement.querySelector('.bfSelect').style.display = 'block';
            this.elementRef.nativeElement.querySelector('.bfSelect').style.width = 'auto';
        }
        else if (this.selectConfig.options.width) {
            this.elementRef.nativeElement.querySelector('.bfSelect').style.width = this.selectConfig.options.width + 'px';
        }
    }

    public ngOnDestroy() {
        clearTimeout(this.changeTimeout);
    }
}
