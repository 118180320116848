<div 
    class="notification"
    [ngClass]="{ 'notification--iconLeft': icon }"
    (click)="removeNotification(text)"
    *ngFor="let text of texts">
    <i  
        class="icon bf-icon bf-icon-{{ icon | lowercase}}"
        *ngIf="icon">
    </i>
    <div 
        class="text"
        [innerHTML]="text"></div>
    <div class="close">
        <i class="bf-icon bf-icon-close-outlined"></i>
        <i class="bf-icon bf-icon-close-filled"></i>
    </div>
</div>


