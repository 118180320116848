<div class="designView"
     [ngClass]="{ 'designView--sidebar' : appService.sideBarView === 1 }">
    
    <!--SIDEBAR-->
    <div class="designView__sidebar"
         *ngIf="appService.sideBarView === 1">
        <translationSidebar></translationSidebar>
    </div>

    <!--MAIN VIEW (CANVAS)-->
    <div class="designView__editor">
        <designEditor></designEditor>
    </div>
</div>