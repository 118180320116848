<div class="bfSelect"
    [ngClass]="{ 'bfSelect--highlight' : selectConfig.options.multi && bfSelectDirective.selectedItems && bfSelectDirective.selectedItems.length > 0,
        'bfSelect--open': bfSelectDirective.opened }"
     #bfSelectDirective=bfSelect
     [bfSelect]="selectConfig"
     [(bfSelectSelected)]="selected"
     (bfSelectSelectedChange)="selectDirectiveChange()"
     (bfSelectInit)="selectDirectiveChange()">
     <div class="bfSelect__content">
     <span class="bfSelect__flags">
        <ng-container *ngFor="let item of bfSelectDirective.selectedItems">
            <ui-flag size="tiny" *ngIf="item.flag"
            [culture]="item.flag"></ui-flag>
        </ng-container>
    </span>
    <span class="bfSelect__label">
        <i class="bfSelect__icon bf-icon bf-icon-{{ icon }}"
           *ngIf="icon"></i>
        <span>
            {{ label }}
        </span>
     <span class="clear__button"
     *ngIf="this.bfSelectDirective.selectConfig.options.multi &&
        (this.bfSelectDirective.selectedItems && this.bfSelectDirective.selectedItems.length >= 1) &&
        (this.bfSelectDirective.selectConfig.selectItems && this.bfSelectDirective.selectConfig.selectItems.length > 8)"
     (click)="clear($event)"><i class="bfSelect__icon bf-icon bf-icon-remove"></i></span>
    </span>
    <i class="bf-icon bf-icon-caret-down pull-right"></i>
</div>

</div>
