import { Component, Input, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { BFSelectConfig, BFSelectItem } from '../index';
import { BFPositionService, BFUtilsService } from '../../../services/index'
import { NgClass, NgStyle, NgIf, NgFor } from '@angular/common';
import { BFSearchComponent } from '../../search/bfSearch.component';
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'bfSelectList',
    templateUrl: './bfSelect.list.component.html',
    standalone: true,
  imports: [NgClass, NgStyle, NgIf, BFSearchComponent, NgFor, UIModule]
})
export class BFSelectListComponent implements AfterViewInit, OnDestroy {
    public selectConfig: BFSelectConfig;
    public position: any = {};

    private resolve: Function;
    private reject: Function;
    private componentElement: Element;
    private targetElementOrPosition: any;
    private onBodyClick: Function;
    private onBodyRightClick: Function;
    private onClosestFixedParentScroll: Function;
    private onWindowScroll: Function;
    private onWindowResize: Function;
    private closeTimeout: any;

    private positionMenuDebounced: Function;

    constructor(
        private elementRef: ElementRef,
        private utilsService: BFUtilsService,
        private positionService: BFPositionService,
        private Renderer2: Renderer2
    ) {
        this.position.left = 200;
        this.position.top = 200;
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.positionMenu();
        });
    }

    public initiate(selectConfig: BFSelectConfig, targetElementOrPosition: any): Promise<BFSelectItem> {
        this.selectConfig = selectConfig;
        this.componentElement = this.elementRef.nativeElement.children[0];
        this.targetElementOrPosition = targetElementOrPosition;
        this.positionMenu();

        this.positionMenuDebounced = BFUtilsService.throttle(this.positionMenu, 1);

        // Timeout need to not trigger close by opening mouseclick
        this.closeTimeout = setTimeout(() => {
            this.onBodyClick = this.Renderer2.listen('document', 'click', (event: MouseEvent) => {
                if (!BFUtilsService.isElementInElement(event.target, this.componentElement)) {
                    this.cancel();
                    event.stopPropagation();
                    event.preventDefault();
                }
            });
            this.onBodyRightClick = this.Renderer2.listen('document', 'contextmenu', (event: MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                this.cancel();
            });
        }, 10);

        this.onWindowResize = this.Renderer2.listen('window', 'resize', (event: MouseEvent) => {
            this.positionMenuDebounced.apply(this);
        });

        this.onWindowResize = this.Renderer2.listen('window', 'scroll', (event: MouseEvent) => {
            this.positionMenuDebounced.apply(this);
        });

        // This is required for positioning the list when the target element is placed in a fixed element
        const closestFixedParent: any = this.positionService.getFirstScrollableParent(targetElementOrPosition);
        if (closestFixedParent !== window.document) {
            this.onClosestFixedParentScroll = this.Renderer2.listen(closestFixedParent, 'scroll', (event: MouseEvent) => {
                this.positionMenuDebounced.apply(this);
            });
        }

        return new Promise<BFSelectItem>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    private onSelect(item: BFSelectItem, event: MouseEvent) {
        if (item.isSeperator) {
            return;
        }

        if (item.onSelect) {
            item.onSelect(item, event);
        }

        // Checkbox item
        if (!this.selectConfig.options.closeOnSelect) {
            return;
        }

        this.resolve(item);
    }

    private positionMenu() {
        let options = this.selectConfig.options;

        if (options.width > 0) {
            (this.componentElement as any).style.width = `${options.width}px`;
        }
        else {
            (this.componentElement as any).style.width = this.targetElementOrPosition.offsetWidth ?
                (this.targetElementOrPosition.offsetWidth + 'px') :
                '150px';
        }

        // Check if popover fits in window
        this.position = this.positionService.smartPosition(
            this.targetElementOrPosition,
            this.componentElement,
            options.placement,
            true);
    }

    private cancel(): void {
        this.reject();
    }

    ngOnDestroy() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        if (this.onBodyClick) {
            this.onBodyClick();
        }
        if (this.onBodyRightClick) {
            this.onBodyRightClick();
        }
        if (this.onClosestFixedParentScroll) {
            this.onClosestFixedParentScroll();
        }
        if (this.onWindowScroll) {
            this.onWindowScroll();
        }
        if (this.onWindowResize) {
            this.onWindowResize();
        }
    }
}
