import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'bfToSentence',
    standalone: true
})
export class BFToSentencePipe implements PipeTransform {

    /**
     * Take an array and make it to a sentence. ['dog', 'cat', 'mouse'] => 'dog, cat and mouse';
     * @param array 
     * @param separator 
     * @param lastSeparator 
     * @param prefix 
     * @param suffix 
     */
    public static toSentence(array: any[], separator:string = ', ', lastSeparator:string = ' and ', prefix:string = "", suffix: string = ""): string {
        let last = '';

        if (!array || !array.length) {
            return '';
        }

        const wrapped: string[] = array.map((item: string) => {
            return prefix + item + suffix;
        });

        if (wrapped.length > 1) {
            if (wrapped.length > 2) {
                last += separator;
            }
            last += wrapped.splice(array.length - 2, 2).join(lastSeparator);
        }

        return wrapped.join(separator) + last;
    }

    public transform(array: any[], separator: string = ', ', lastSeparator:string = ' and ', prefix: string = '', suffix: string = ''): string {
        return BFToSentencePipe.toSentence(array, separator, lastSeparator, prefix, suffix);
    }
}
