import {ISerializable} from "shared/interfaces/serializable";

export class TranslatorModel implements ISerializable<TranslatorModel> {
    public id: string;
    public email: string;
    public isDefault: boolean;
    public name: string;

    constructor(){ }

    public deserialize(data:any):TranslatorModel{
        this.id = data.id;
        this.email = data.email;
        this.isDefault = data.isDefault;
        this.name = data.name;

        return this;
    }
}
