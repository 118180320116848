// libs
import {
    Component,
    ElementRef,
    Renderer2,
    ViewChild
} from '@angular/core';
import {
  BFPopoverComponent,
  BFPositionService,
  BFConfirmDialogService,
  BFDialogResponse, BFTooltipDirective, BFInlineEditDirective
} from "../../../../../../../libs/material";
import {NgStyle} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TextModel} from "shared/models/text.model";
import {TranslationModel} from "shared/models/translation.model";
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'textPopOver',
    styleUrls: ['textPopOver.component.scss'],
    templateUrl: 'textPopOver.component.html',
    standalone: true,
  imports: [NgStyle, BFTooltipDirective, BFInlineEditDirective, FormsModule, UIModule]
})
export class TextPopOverComponent extends BFPopoverComponent {
    @ViewChild('textarea', { static: true }) textareaElement: ElementRef;
    public _callback: Function;
    public textValue: string;
    public textName: string;
    public text: TextModel;
    public originalTranslation: TranslationModel;

    constructor(elementRef: ElementRef,
        positionService: BFPositionService,
        Renderer2: Renderer2,
        private confirmDialogService: BFConfirmDialogService) {
        super(elementRef, positionService, Renderer2);
    }

    public initiate(text: TextModel, element: Element, originalTranslation: TranslationModel): Promise<TextModel> {
        this.text = text;
        this.textValue = text.value;
        this.textName = text.name;
        this.originalTranslation = originalTranslation;

        setTimeout(() => {
            this.textareaElement.nativeElement.focus();
        }, 10);

        this.open(element, 'bottom').then((value: boolean) => {
        })

        return new Promise<TextModel>(resolve => {
            this._callback = resolve;
        });

    }

    public close(): void {
        this.text.name = this.textName;
        this._callback(this.text);
    }

    public clearTranslation(event: any): void {
        this.confirmDialogService.show('Clear translatable text',
            'If you clear this text from being translatable, you will loose all translations of it.',
            'Clear',
            'Cancel').then((response: BFDialogResponse<any>) => {
                if (!response.cancel)
                    this._callback(null);
            });
    }

    public apply(event: KeyboardEvent = null): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.text.name = this.textName;
        this.text.value = this.textValue;

        this._callback(this.text);
    }
}
