//TODO: This should be moved to @bannerflow/material
import { Component, Input } from '@angular/core';
import { BFTooltipDirective } from '../../../../libs/material/components/tooltip/bfTooltip.directive';

@Component({
    selector: 'bfHelpIcon',
    styleUrls: ['bfHelpIcon.component.scss'],
    templateUrl: './bfHelpIcon.component.html',
    standalone: true,
    imports: [BFTooltipDirective]
})
export class BFHelpIconComponent{
    @Input() public text: string;
    
    constructor() { }

}