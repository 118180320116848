import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BFColorService } from '../bfColorPicker/bfColor.service';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    styleUrls: ['bfSwatches.component.scss'],
    selector: 'bfSwatches',
    templateUrl: 'bfSwatches.component.html',
    standalone: true,
    imports: [NgFor, NgIf, NgClass]
})
export class BFSwatchesComponent implements OnChanges {
    @Input('config') private config: any; // Should we have this or categories directly?
    @Input('color') color: string;
    @Output('colorChange') colorChange = new EventEmitter<string>(true);

    private rgbaColor:string;

    public categories: BFSwatchesCategory[];
    //private selectedColor: BFSwatchesColor;



    constructor(private bfColorService:BFColorService) {
    }

    private selectColor(color: string, emit:boolean = true, event?:Event) {
        if(this.bfColorService.validateColor(color)) {
            this.color = color;
            this.rgbaColor = this.toRgba(color);

            //only emit if this is a selection
            if(emit)
                this.colorChange.emit(color);

            if(event) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }

    private toRgba(color: string) {
        return this.bfColorService.rgbText(color);
    }

    ngOnChanges(changes: any) {

        //If no config is se, use default colors
        if(!this.config) {
            this.setDefaultConfiguration()
        }

        this.categories = this.config.categories;
        this.selectColor(this.color, false);
    }

    private setDefaultConfiguration() {
        this.config = new BFSwatchesConfig([
            new BFSwatchesCategory([
                new BFSwatchesColor("#000"),
                new BFSwatchesColor("#222"),
                new BFSwatchesColor("#444"),
                new BFSwatchesColor("#666"),
                new BFSwatchesColor("#888"),
                new BFSwatchesColor("#aaa"),
                new BFSwatchesColor("#ccc"),
                new BFSwatchesColor("#ddd"),
                new BFSwatchesColor("#eee"),
                new BFSwatchesColor("#fff")
            ],
            //Columns
            [
                //Deep red
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#980000"),
                    new BFSwatchesColor("#E6B8AF"),
                    new BFSwatchesColor("#DD7E6B"),
                    new BFSwatchesColor("#CC4125"),
                    new BFSwatchesColor("#A61C00"),
                    new BFSwatchesColor("#5B0F00"),
                ]),
                //Red
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#FF0000"),
                    new BFSwatchesColor("#F4CCCD"),
                    new BFSwatchesColor("#EA9999"),
                    new BFSwatchesColor("#E06666"),
                    new BFSwatchesColor("#CC0000"),
                    new BFSwatchesColor("#660000"),
                ]),
                //Orange
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#FF9901"),
                    new BFSwatchesColor("#FCE5CD"),
                    new BFSwatchesColor("#F9CB9C"),
                    new BFSwatchesColor("#F6B26B"),
                    new BFSwatchesColor("#E69138"),
                    new BFSwatchesColor("#783F05"),
                ]),
                //Yellow
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#FFFF02"),
                    new BFSwatchesColor("#FFF2CC"),
                    new BFSwatchesColor("#FFE599"),
                    new BFSwatchesColor("#FFD966"),
                    new BFSwatchesColor("#F1C233"),
                    new BFSwatchesColor("#7F6000"),
                ]),
                //Green
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#00FF02"),
                    new BFSwatchesColor("#D9EBD3"),
                    new BFSwatchesColor("#B6D7A8"),
                    new BFSwatchesColor("#93C47D"),
                    new BFSwatchesColor("#6AA84F"),
                    new BFSwatchesColor("#274E13"),
                ]),
                //Cyan
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#01FFFF"),
                    new BFSwatchesColor("#CFE0E3"),
                    new BFSwatchesColor("#A2C4CA"),
                    new BFSwatchesColor("#76A5AF"),
                    new BFSwatchesColor("#44818E"),
                    new BFSwatchesColor("#0C343D"),
                ]),
                //Pastell blue
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#4A86E8"),
                    new BFSwatchesColor("#C9D9F8"),
                    new BFSwatchesColor("#A4C2F4"),
                    new BFSwatchesColor("#6D9EEB"),
                    new BFSwatchesColor("#3D78D8"),
                    new BFSwatchesColor("#1C4587"),
                ]),
                //Blue
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#0000FF"),
                    new BFSwatchesColor("#CFE2F3"),
                    new BFSwatchesColor("#9FC5E7"),
                    new BFSwatchesColor("#6FA8DC"),
                    new BFSwatchesColor("#3D85C6"),
                    new BFSwatchesColor("#073763"),
                ]),
                //Purple
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#9900FF"),
                    new BFSwatchesColor("#D9D2E9"),
                    new BFSwatchesColor("#B4A7D7"),
                    new BFSwatchesColor("#8E7CC3"),
                    new BFSwatchesColor("#674EA7"),
                    new BFSwatchesColor("#20124D"),
                ]),
                //Some strange color between purple and red
                new BFSwatchesCategoryColumn([
                    new BFSwatchesColor("#A8276B"),
                    new BFSwatchesColor("#EAD1DC"),
                    new BFSwatchesColor("#D5A6BD"),
                    new BFSwatchesColor("#C27BA0"),
                    new BFSwatchesColor("#A64D79"),
                    new BFSwatchesColor("#4C1130"),
                ])

            ])
        ]);
    }
}

export class BFSwatchesConfig {
    constructor(public categories?: BFSwatchesCategory[]) {

    }
}

export class BFSwatchesColor {
    constructor(public color?: string, public name?: string) {
    }
}

export class BFSwatchesCategoryColumn {

    constructor(public colors?: BFSwatchesColor[]) {

    }
}

export class BFSwatchesCategory {

    constructor(public colors?: BFSwatchesColor[],
                public columns?: BFSwatchesCategoryColumn[],
                public name?: string) {

    }
}

