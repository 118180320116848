// libs
import { Injectable } from '@angular/core';
import { BFNotificationService } from '../../../libs/material/index';
import {ApiService} from "shared/services/api.service";
import {LandingPageModel} from "shared/models/landingPage.model";
import {TranslationModel} from "shared/models/translation.model";
import {PublishState} from "shared/enums/publishState.enum";
import {ISerializable} from "shared/interfaces/serializable";


interface CustomCname {
    localizationId: string;
    cname: string;
}

export interface Cnames {
    defaultCname: string;
    customCnames: CustomCname[];
}

@Injectable({ providedIn: 'root' })
export class PublishService {

    private cNamesPromise: Promise<any>;
    private maxBatchSize: number = 20;

    constructor(private readonly apiService: ApiService,
        private readonly notificationService: BFNotificationService) { }

    public getCnames(force: boolean = false): Promise<Cnames> {
        if (!this.cNamesPromise || force) {
            this.cNamesPromise = new Promise<any>((resolve: Function) => {
                return this.apiService.get('publish', `getcnames`).then((cnames: any) => {
                    resolve(cnames);
                });
            });
        }

        return this.cNamesPromise;
    }

    public getCname(
        localizationId: string, accountSlug: string, brandId: string, cnames: Cnames): string {
        let cname: string = `${cnames.defaultCname}/${accountSlug}/${brandId}`;
        if (cnames.customCnames) {
            cnames.customCnames.some((d) => {
                if (d.localizationId === localizationId) {
                    cname = d.cname;
                    return true;
                }
            });
        }

        return (cname + '/').toLowerCase();
    }

    public checkNameAvailability(
        name: string, landingPageId: string, additionalSlugs: string[] = null, translationId: string = null): Promise<any> {

        let apiUrl = `publishslugistaken?name=${name}&landingPageId=${landingPageId}`;

        if (translationId) {
            apiUrl += `&translationId=${translationId}`;
        }

        return this.apiService.post('publish', apiUrl, additionalSlugs);
    }

    public getDefaultPublishName(
        pattern: string, landingPageName: string, cultureCode: string): string {
        return pattern
            .replace(/%landing_page_name%/, landingPageName)
            .replace(/%culture_code%/, cultureCode);
    }

    public publish(
        landingPage: LandingPageModel,
        translations: TranslationModel[],
        successCallback: Function,
        errorCallback: Function): Promise<PublishProgressResponse> {

        return new Promise<PublishProgressResponse>((resolve: Function, reject: Function) => {
            let action = `publish?landingPageId=${landingPage.id}`;
            const ids = translations.map(t => t.id);
            this._publish(action, ids, this.maxBatchSize, true, successCallback, errorCallback)
                .then((response: PublishProgressResponse) => {
                    landingPage.lastPublished = response.progressDate;

                    const allVersionsPublished =
                        landingPage.translations.length + 1 === translations.length;
                    const publishState = allVersionsPublished ?
                        PublishState.Published : PublishState.PublishedWithPendingUpdates;
                    landingPage.publishState = publishState;

                    translations.forEach((translation: TranslationModel) => {
                        translation.publishState = PublishState.Published;
                    });

                    resolve(response);
                });
        });
    }

    public unpublish(
        landingPage: LandingPageModel,
        translations: TranslationModel[],
        successCallback: Function,
        errorCallback: Function): Promise<PublishProgressResponse> {

        return new Promise<PublishProgressResponse>((resolve: Function, reject: Function) => {
            let action = `unpublish?landingPageId=${landingPage.id}`;
            const ids = translations.map(t => t.id);

            this._publish(action, ids, this.maxBatchSize, true, successCallback, errorCallback)
                .then((response: PublishProgressResponse) => {
                    translations.forEach((translation: TranslationModel) => {
                        translation.publishState = PublishState.Unpublished;
                    });

                    resolve(response);
                });
        });
    }

    private getTranslationIds(landingPage: LandingPageModel): string[] {
        let translationIds: string[] = [landingPage.originalTranslation.id];
        if (landingPage.translations) {
            landingPage.translations.forEach((translation) => {
                translationIds.push(translation.id);
            });
        }

        return translationIds;
    }

    private _publish(
        action: string,
        translationIds: string[],
        batchSize: number,
        force: boolean = false,
        successCallback: Function = null,
        errorCallback: Function = null): Promise<PublishProgressResponse> {

        if (force) action += `&force=true`;

        let queue = [...translationIds];
        let publishProgress: PublishProgressResponse =
            new PublishProgressResponse();
        let successCount: number = 0;
        let errorCount: number = 0;

        return new Promise<PublishProgressResponse>((resolve) => {
            const publishTranslation = () => {
                if (queue.length === 0) {
                    setTimeout(() => {
                        resolve(publishProgress);
                        this.notificationService.show(
                            `Landing page successfully published!`, 'success', 'top', undefined, 'finished');
                    }, 1000);
                    return;
                }

                const postData: string[] = [];
                const publishCount = Math.min(batchSize, queue.length);
                for (let i = 0; i < publishCount; i++) {
                    postData.push(queue.pop());
                }

                this.apiService.post(`publish`, action, postData).then((data: any) => {

                    const response: PublishProgressResponse =
                        new PublishProgressResponse().deserialize(data);
                    publishProgress.progressDate = response.progressDate;
                    publishProgress.successfulTranslationIds.push(
                        ...response.successfulTranslationIds);
                    publishProgress.failedTranslations.push(
                        ...response.failedTranslations);

                    successCount += response.successfulTranslationIds.length;
                    errorCount += response.failedTranslations.length;

                    if (response.failedTranslations.length == 0) {
                        successCallback(
                            successCount,
                            translationIds.length,
                            errorCount);
                    } else {
                        errorCallback(
                            successCount,
                            translationIds.length,
                            errorCount);
                    }

                    publishTranslation();

                })
                    .catch((response) => {
                        let responseMsg = response.errorMessage || 'Unknown error';
                        let msg = `Something went wrong. Error: "${responseMsg}"`;
                        this.notificationService.show(msg, 'error', 'top', undefined, 'alert');

                        resolve(new PublishProgressResponse());
                    });
            };

            publishTranslation();
        });
    }
}

export class PublishProgressResponse
    implements ISerializable<PublishProgressResponse> {

    public progressDate: Date;
    public successfulTranslationIds: string[] = [];
    public failedTranslations: FailedTranslation[] = [];

    public deserialize(json: any) {
        if (!json)
            return null;

        let p = new PublishProgressResponse();
        p.progressDate = json.progressDate ?
            new Date(json.progressDate) : new Date();
        p.successfulTranslationIds = json.successfulTranslations ?
            json.successfulTranslations.map((id: string) => {
                return id;
            }) : [];

        if (json.failedTranslations) {
            for (var translation in json.failedTranslations) {
                p.failedTranslations.push(
                    new FailedTranslation().deserialize(
                        {
                            translationId: translation,
                            error: json.failedTranslations[translation]
                        }))
            }
        } else {
            p.failedTranslations = [];
        }

        return p;
    }
}

export class FailedTranslation
    implements ISerializable<FailedTranslation> {

    public translationId: string;
    public error: string;

    public deserialize(json: any) {
        if (!json)
            return null;

        let t = new FailedTranslation();
        t.error = json.error;
        t.translationId = json.translationId;

        return t;
    }
}
