import { Directive, ElementRef, Input, Renderer2, OnDestroy, OnInit, Output, EventEmitter, ComponentRef } from '@angular/core';

import { BFMaterial } from '../../bfMaterial';
import { BFComponentContainer } from '../../containers/bfComponent.container';

import { BFSelectConfig } from './bfSelectConfig';
import { BFSelectItem } from './bfSelectItem';
import { BFSelectListComponent } from './list/bfSelect.list.component';

@Directive({
    selector: '[bfSelect]',
    exportAs: 'bfSelect' //the name of the variable to access the Directive
    ,
    standalone: true
})

export class BFSelectDirective implements OnDestroy, OnInit {

    @Input('bfSelect') selectConfig: BFSelectConfig;
    @Input('bfSelectSelected')
    get selected() {
        return this._selected;
    }
    set selected(val) {
        this._selected = val instanceof Array ? val : [val];

        if (this.selectConfig.options.multi) {
            this.handleMultiSelect();
        }
    }

    @Output('bfSelectSelectedChange') selectedChange: EventEmitter<any> = new EventEmitter<any>();
    @Output('bfSelectClose') selectClose: EventEmitter<any> = new EventEmitter<any>();
    @Output('bfSelectInit') selectInit: EventEmitter<any> = new EventEmitter<any>();

    private _selected: any = null;

    protected select: EventEmitter<BFSelectItem> = new EventEmitter<BFSelectItem>();

    protected element: any;
    protected opened: boolean;
    protected menuComponentRef: ComponentRef<BFSelectListComponent>;

    //Listeners
    protected onClick: Function;

    public selectedItems: BFSelectItem[];

    constructor(protected elementRef: ElementRef,
        protected Renderer2: Renderer2,
        protected componentContainer: BFComponentContainer,
        protected bfMaterial: BFMaterial) {
    }

    ngOnInit() {
        this.onClick = this.Renderer2.listen(this.elementRef.nativeElement, 'click', (event: MouseEvent) => {

            if (!this.opened) {
                this.open(this.elementRef.nativeElement);
            } else {
                this.close();
            }

            event.preventDefault();
        });

        //Add listeners to items in list
        this.selectConfig.selectItems.forEach((item: BFSelectItem) => {
            item.onSelect = (i: any, event: any) => {
                event.stopPropagation();
                event.preventDefault();
                this.selectItem(item);
            };
        });

        // setTimeout approved by Google
        // https://angular.io/docs/ts/latest/cookbook/component-communication.html#!#parent-to-view-child
        setTimeout(() => {
            if (this.selectConfig.options.multi) {
                this.handleMultiSelect();
            } else {

                // default to first item in list of selectable items (this behavior should change in the future)
                let selected = this.selected[0] ?
                    this.selectConfig.selectItems.find((item: BFSelectItem) => {
                        return item.value === this.selected[0];
                    }) :
                    this.selectConfig.selectItems[0];

                if (selected !== undefined) {
                    this.selectItem(selected, false);
                }
            }

            this.selectInit.emit();
        });
    }

    private selectItem(item: BFSelectItem, emit: boolean = true): void {
        //If multi selection
        if (this.selectConfig.options.multi) {
            let maxCount: number = this.selectConfig.options.maxSelectedItems || 0;

            //Set all to unselected if 
            if (maxCount === 1) {
                this.selectConfig.selectItems.forEach((i: BFSelectItem) => {
                    if (item !== i) {
                        i.selected = false;
                    }
                });
            }

            item.selected = !item.selected;

            this.selectedItems = this.selectConfig.selectItems.filter((item: BFSelectItem) => {
                return item.selected;
            });

            this.selected = this.selectedItems.map((item: BFSelectItem) => {
                return item.value;
            });

            if (emit) {
                this.selectedChange.emit(this.selected);
            }

        } else {
            this.selectedItems = [item];
            this.selected = item ? item.value : null;

            if (emit) {
                this.selectedChange.emit(this.selected[0]);
            }
        }
    }

    private handleMultiSelect() {
        //setTimeout approved by Google
        //https://angular.io/docs/ts/latest/cookbook/component-communication.html#!#parent-to-view-child
        setTimeout(() => {

            if (this.selected) {
                this.selectConfig.selectItems.forEach((item: BFSelectItem) => {
                    item.selected = this.selected.some((obj: any) => {
                        return obj === item.value
                    });
                });
            }

            // //If too many items are selected prevent further selection
            let maxCount: number = this.selectConfig.options.maxSelectedItems || 0;
            let currentlySelected = this.selected ? this.selected.length : 0;

            if (maxCount > 1) {
                this.selectConfig.selectItems.forEach((item: BFSelectItem) => {
                    item.disabled = !item.selected && currentlySelected >= maxCount;
                });
            }

            //Set internal selected items value
            this.selectedItems = this.selectConfig.selectItems.filter((item: BFSelectItem) => {
                return item.selected;
            });
        });

    }

    public open(positionOrElement: any) {
        if (this.opened || !this.selectConfig || !this.selectConfig.selectItems || this.selectConfig.selectItems.length === 0) return;

        this.menuComponentRef = this.componentContainer.attach(BFSelectListComponent, this.bfMaterial.rootViewContainerRef);
        this.menuComponentRef.instance.initiate(this.selectConfig, positionOrElement).then((item: BFSelectItem) => {

            this.select.emit(item);

            if (this.selectConfig.options.closeOnSelect)
                this.close();
        }).catch((value: any) => {
            this.close();
        });
        this.opened = true;
    }

    public close(emit: boolean = true) {
        this.opened = false;

        if (emit) {
            this.selectClose.emit();
        }
        this.componentContainer.detach(this.menuComponentRef);
    }

    public clear(): void {
        this.selectedItems = [];
        this.selectConfig.selectItems.forEach((item) => item.selected = false);
        this.selected = [];
        this.selectedChange.emit(this.selected);
    }

    //Remove listeners
    ngOnDestroy() {
        this.onClick();
        this.close(false);
    }
}