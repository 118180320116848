import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class UtilsService {
    constructor() {  }

    public static generateGUID() : string{
        var s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        };

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    public static isMac() : boolean {
        return navigator.userAgent.indexOf('Mac OS X') != -1;
    }
}
