// libs
import {
    Component,
    ViewChild,
    ElementRef,
    Renderer2,
    AfterViewInit,
    ComponentRef,
    NgZone,
    Input,
} from '@angular/core';
import {
  BFComponentContainer,
  BFMaterial,
  BFDialogResponse,
} from '../../../../../../../libs/material/index';
import {InspectorComponent} from "design/views/design/components/designEditor/inspector/inspector.component";
import {NgIf} from "@angular/common";
import {LandingPageService} from "shared/services/landingPage.service";
import {TextService} from "shared/services/text.service";
import {UserService} from "shared/services/user.service";
import {DesignService} from "design/design.service";
import {UploadDialogService} from "shared/components/uploadDialog/uploadDialog.service";
import {HotkeysService} from "shared/services/hotkeys.service";
import {TranslationModel} from "shared/models/translation.model";
import {TextModel} from "shared/models/text.model";
import {ResourceTranslationModel} from "shared/models/resourceTranslation.model";
import {LandingPageModel} from "shared/models/landingPage.model";
import {UserModel} from "shared/models/user.model";
import {FileModel} from "shared/models/file.model";
import {ScribeWrapper} from "shared/wrappers/scribe.wrapper";
import {UIModule} from "@bannerflow/ui";


@Component({
    selector: 'artboard',
    templateUrl: 'artboard.component.html',
    standalone: true,
  imports: [NgIf, UIModule],
    styleUrls: ['artboard.component.scss']
})
export class ArtboardComponent implements AfterViewInit {
    @Input('isShowcase') isShowcase = false;

    private iframe: HTMLIFrameElement;
    private window: any;
    private scribeContainers: Array<ScribeContainer> = new Array<ScribeContainer>();
    private imageContainers: any[] = [];
    public loading: boolean = true;
    private inspector: ComponentRef<InspectorComponent>;
    private selectedTextTimeout;
    private onSelectText: any;
    private onTextChange: any;
    private onIframeMouseEvent: any;
    private onIframeKeyboardEvent: any;
    private onLandingPageSaved: any;
    private onCurrentTranslationChanged: any;
    private onScroll: Function;
    private onIframeLoad: any;
    private onResourceChange: any;
    private imageOverlay;

    @ViewChild('artboardIframe', { static: false }) artboardIframe: ElementRef;

    constructor(
        private Renderer2: Renderer2,
        private bfMaterial: BFMaterial,
        private bfComponentContainer: BFComponentContainer,
        private landingPageService: LandingPageService,
        private textService: TextService,
        private userService: UserService,
        public designService: DesignService,
        private uploadDialogService: UploadDialogService,
        private ngZone: NgZone,
        private hotkeysService: HotkeysService) {
    }

    ngAfterViewInit(): void {
      this.iframe = this.artboardIframe?.nativeElement;
      this.addIframeLoader();
      if (this.textService.currentTranslation) {
        this.setIframeSrc(this.textService.currentTranslation);
      }

      //Change of language
      this.onCurrentTranslationChanged = this.textService.currentTranslationChange.subscribe((translation: TranslationModel) => {
        this.addIframeLoader()
        //Change iframe preview source based on selected translation
        this.setIframeSrc(translation);
      });

      //When banner is saved
      this.onLandingPageSaved = this.landingPageService.landingPageSaved.subscribe(() => {

        //Reload the preview when the landingpage is saved
        if (this.window) {
          this.addIframeLoader()
          this.window.location.reload();
        }
      });
    }

  private addIframeLoader() {
    if (!this.iframe) return;
    this.destroy();

    this.loading = true;

    this.onIframeLoad = this.Renderer2.listen(this.iframe, 'load', (e: any) => {
      if (this.onIframeLoad) {
        this.onIframeLoad();
        this.onIframeLoad = null;
      }
      this.window = this.artboardIframe?.nativeElement?.contentWindow || this.artboardIframe?.nativeElement?.contentDocument;
      if (this.isShowcase) {
        this.try(() => this.initMouseEventBubbler);
        this.try(() => {
          const anchorTags = this.iframe?.contentDocument?.querySelectorAll('a') || [];
          Array.from(anchorTags).forEach((anchor: HTMLAnchorElement) => {
            if (anchor) {
              const href = anchor.getAttribute('href');
              const hasHref = href != null && href !== '';

              if (hasHref && href.charAt(0) !== '#' && !href.startsWith('javascript:')) {
                anchor.addEventListener('click', (event) => {
                  event.preventDefault();
                  window.open(href, '_blank');
                });
              }
            }
          });
        });
      }
      else {
        this.initIframe();
      }
      this.loading = false;
    });
  }

    private try(Fn : Function) {
      try {
        Fn();
      } catch (e) {
        console.error(e);
      }
    }

    //Init iframe
    private initIframe() {
        //Hack to get save-hotkeys to work when iframe has focus
        this.try(() => {
          this.hotkeysService.bindKeyOnElement(['ctrl+s', 'command+s'], this.window.document, (e: any) => {
            this.ngZone.run(() => {
              this.hotkeysService.trigger('ctrl+s');
            });
            e.preventDefault();
            return false;
          });
        });
        this.try(() => this.injectStyle());
        this.try(() => this.initScribe());
        this.try(() => this.setIndexes());

        this.try(() => {
          this.onSelectText = this.designService.selectedTextChange.subscribe((text: TextModel) => {
            this.highlightByKey(text ? text.key : null);
          });
        });

        this.try(() => {
          this.onTextChange = this.textService.textChange.subscribe((text: TextModel) => {
            this.setText(text);
          });
        });

        this.try(() => {

          //Image changed externally
          this.onResourceChange = this.textService.resourceChange.subscribe((resource: ResourceTranslationModel) => {
            for (let image of this.getImageElements(resource.originalResourceId)) {
              image.src = resource.url;
            }
          });
        });

        this.try(() => {
          //Scroll inside of iframe
          this.window.document.addEventListener('scroll', this.onScroll = (event) => {
            if (this.inspector) {
              this.inspector.instance.positionMenu();
            }
          });
        });

        this.try(() => this.disableLinks());
        //this.initKeyboardEventBubbler()
        this.try(() => this.initMouseEventBubbler());
        this.try(() => this.injectImageOverlay());
    }

    private setIframeSrc(translation: TranslationModel): void {
        this.loading = true;
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            this.userService.get()
                .then((user: UserModel) => {
                    if (this.isShowcase) {
                        this.iframe.src = `/landing-page-builder/api/render/${user.accountSlug}/${user.brandId}/showcase/${landingPage.id}/${translation.id}/index.html`;
                    }
                    else {
                        this.iframe.src = `/landing-page-builder/api/render/${user.accountSlug}/${user.brandId}/render/${landingPage.id}/${translation.id}/index.html`;
                    }
                });
        });
    }

    //Init all scribe instances and add them to array
    private initScribe() {
        for (let element of this.getTextElements()) {
            let key = this.getKey(element);
            let translatedText = this.textService.getTextByKey(key);
            let originalText = this.textService.getOriginalTextByKey(key);

            //Check if this is an inline or block tag (note that css display property doesn't matter)
            if (element.nodeName == "DIV") {
                this.textService.setAllowBlockElement(key, true);
            } else { //only if the tag has been changed in code mode
                this.textService.setAllowBlockElement(key, false);
            }

            let scribe = new ScribeWrapper(this.ngZone, element, true, originalText.isBlockElement);
            let scribeContainer = new ScribeContainer();

            element.setAttribute('spellcheck', 'false');

            //Inject the translated text if it exists to show text changes made without saving
            if (translatedText) {
                scribe.setContent(translatedText.value, true);
            }

            scribeContainer.scribe = scribe;

            //Text changed in scribe instance
            scribeContainer.onChange = scribe.change.subscribe(() => {
                this.textService.setTextValue(key, scribe.getContent());
            });

            //Focus gained in scribe instance
            scribeContainer.onFocus = scribe.focus.subscribe((scribe: ScribeWrapper) => {
                this.openInspector(scribe);
                const text = this.textService.getTextByKey(this.getKey(scribe.textInput));
                // View port top is used for animate the sidebar scroll
                text.viewPortTop = scribe.textInput.getBoundingClientRect().top + this.iframe.getBoundingClientRect().top;
                this.clearSelectedTextTimeout();
                this.selectedTextTimeout = setTimeout(() => {
                    this.designService.selectText(text, 'canvas');
                }, 100);
            });

            //DO THIS BACKEND
            //scribe.element.contentEditable = 'false';

            //Prevent click on this area to bubble up mousedown that closes inspector REMOVE!!!!
            //scribe.element.addEventListener('click', scribeContainer.onClick = (event) => {
            //event.target.contentEditable = 'true';
            //event.stopPropagation();

            //});

            //Focus lost in scribe instance
            //scribeContainer.onBlur = scribe.blur.subscribe((scribe) => {
            //this.closeInspector(scribe);
            //this.designService.selectText.emit(null);
            //});



            this.scribeContainers.push(scribeContainer);
        }
    }

    //Inject styling in iframe
    private injectStyle() {
        let styleTag = this.artboardIframe?.nativeElement?.contentWindow.document.createElement('style');
        styleTag.type = 'text/css';
        styleTag.innerHTML = `
            [data-bftext] {
                outline: none;
            }
            [data-bftext]::before, [data-bfcircle]::before {
                content: attr(data-bfnumber);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                user-select: none;
                background-color: #888;
                border-radius: 200px;
                line-height: 16px;
                width: 16px;
                font-size: 10px;
                text-align: center;
                color: #fff;
                overflow: hidden;
                transform: translate3d(-50%, -50%, 0);
                opacity: 1;
                transition: background-color 0.3s ease;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                font-weight: normal;
                padding: 0;
                margin: 0;
                letter-spacing: 0;
            }
            [data-bftext].bfActive::before,
            [data-bftext]:focus::before {
                background-color: #f14040;
            }
            [data-bftext], img[data-bfimage] {
                outline: 1px dashed #888 !important;
                transition: outline;

            }
            [data-bftext]:focus, img[data-bfimage]:hover, [data-bftext].bfActive, [data-bfimage].bfActive {
                outline: 1px dashed #FF4838 !important;
            }
            img[data-bfimage] {
                cursor: pointer;
            }
            [data-bfcircle] {
                position: relative;
                width: 0;
                height: 0;
                overflow: visible;
                pointer-events: none;
            }
            [data-bfcircle]::before {
                left: 16px;
                top: 16px;
                content: attr(data-bfcircle);
                pointer-events: none;
                background-color: #FF4838;
            }
            .bfImageOverlay {
                box-sizing: border-box;
                position: absolute;
                pointer-events:none;
                opacity: 0;
                transition: opacity 0.2s ease;
                z-index: 999999999999999;

            }
            .bfImageOverlay--active {
                opacity: 1;
            }

        `;

        //Loop all text elements in iframe
        for (let element of this.getTextElements()) {
            //Prevent display: static (for number badge position)
            let position = this.getCalculatedStyle(element, 'position');
            if (position === 'static')
                element.style.position = 'relative';

            //Prevent inline display
            //let display = this.getCalculatedStyle(element, 'display');
            //if(display === 'inline')
            //element.style.display = 'inline-block';
        }

        //Loop all image elements in iframe
        let i = 0;
        for (let element of this.getImageElements()) {
            this.setupImage(element, i)
            i++;
        }

      this.artboardIframe?.nativeElement?.contentWindow.document.body.appendChild(styleTag);
    }

    //Get elements or elemenet based on key in iframe
    private getTextElements(key?: string) {
        let k = (key ? '="' + key + '"' : '');
        return this.artboardIframe?.nativeElement?.contentWindow.document.querySelectorAll(`[data-bftext${k}]`);
    }

    //Get elements or elemenet based on key in iframe
    private getImageElements(key?: string) {
        let k = (key ? '="' + key + '"' : '');
        //return this.window.document.querySelectorAll('img') //REMOVE THIS!!!!!
        return this.artboardIframe?.nativeElement?.contentWindow.document.querySelectorAll(`[data-bfimage${k}]`);
    }

    private injectImageOverlay() {
        let overlay = this.artboardIframe?.nativeElement?.contentWindow.document.createElement('div');

        overlay.className = 'bfImageOverlay';
        this.artboardIframe?.nativeElement?.contentWindow.document.body.appendChild(overlay);
        this.imageOverlay = overlay;

    }

    private showImageOverlay(element) {
        let position = element.getClientRects()[0];
        let body = this.artboardIframe?.nativeElement?.contentWindow.document.body;

        if (position) {
            this.imageOverlay.style.left = (position.left + body.scrollLeft) + 'px';
            this.imageOverlay.style.top = (position.top + + body.scrollTop) + 'px';
            this.imageOverlay.style.width = position.width + 'px';
            this.imageOverlay.style.height = position.height + 'px';
            this.imageOverlay.classList.add('bfImageOverlay--active');
            this.imageOverlay.setAttribute('data-bfcircle', element.getAttribute('data-bfnumber'))
        }

    }

    private hideImageOverlay() {
        this.imageOverlay.classList.remove('bfImageOverlay--active');
    }

    //prevent links from being clickable
    private disableLinks() {
        for (let link of this.artboardIframe?.nativeElement?.contentWindow.document.querySelectorAll(`a`)) {
            let href = link.getAttribute('href') || "";
            if (href.indexOf('javascript:') !== 0) {
                link.setAttribute('onclick', 'event.preventDefault()');
            }
        }

    }

    //Click should bubble up to parent document to close dialogs etc
    private initMouseEventBubbler() {
        this.onIframeMouseEvent = (event: MouseEvent) => {
            // from http://stackoverflow.com/a/32010791/217408
            let mouseEvent = new MouseEvent(event.type, { bubbles: true });
            // TODO need to fix this
            //this.Renderer2.invokeElementMethod(this.iframe, 'dispatchEvent', [mouseEvent]);
        };
      this.artboardIframe?.nativeElement?.contentWindow.addEventListener('click', this.onIframeMouseEvent);
      this.artboardIframe?.nativeElement?.contentWindow.addEventListener('touchstart', this.onIframeMouseEvent);
      this.artboardIframe?.nativeElement?.contentWindow.addEventListener('mousedown', this.onIframeMouseEvent);
      this.artboardIframe?.nativeElement?.contentWindow.addEventListener('mouseup', this.onIframeMouseEvent);
    }

    //Bubble up keyboard events
    private initKeyboardEventBubbler() {
        this.onIframeKeyboardEvent = (event: KeyboardEvent) => {
            // from http://stackoverflow.com/a/32010791/217408
            let keyboardEvent = new Event(event.type, event);
            // TODO need to fix this
            //this.Renderer2.invokeElementMethod(this.iframe, 'dispatchEvent', [keyboardEvent]);
        };
      this.artboardIframe?.nativeElement?.contentWindow.addEventListener('keydown', this.onIframeKeyboardEvent);
    }

    private setupImage(element, index) {
        let id = this.getResourceId(element);
        let currentResource = this.textService.getCurrentResource(id);

        //Replace image with a translation if it exist (to show unsaved changes);
        if (currentResource) {
            element.src = currentResource.url;
        }

        //Set index to later display correct index
        element.setAttribute('data-bfnumber', index + 1);

        //Replace image
        let onClick = this.Renderer2.listen(element, 'click', (event) => {
            this.ngZone.run(() => {
                this.uploadResource(id, element);
            });
        })

        //Make sure position is not static so number badge can be position
        let position = this.getCalculatedStyle(element, 'position');
        if (position === 'static')
            element.style.position = 'relative';

        //Show hover overlay
        let onMouseOver = this.Renderer2.listen(element, 'mouseover', () => {
            this.showImageOverlay(element);
        });

        //Hide overlay
        let onMouseOut = this.Renderer2.listen(element, 'mouseout', () => {
            this.hideImageOverlay();
        });

        //Save references for destroy method
        this.imageContainers.push({
            element: element,
            onClick: onClick,
            onMouseOver: onMouseOver,
            onMouseOut: onMouseOut
        });
    }

    //Loop through all scribe instances or the one with a certain key
    private each(func: (scribe: ScribeWrapper) => void, key?: string) {
        for (let scribeContainer of this.scribeContainers) {
            if (!key || this.getKey(scribeContainer.scribe.textInput) === key)
                func(scribeContainer.scribe);
        }
    }

    //Set all texts by a certain key (text.key)
    private setText(text: TextModel) {
        this.each((scribe: ScribeWrapper) => {
            scribe.setContent(text.value);
        }, text.key);
    }

    //Get key from element
    private getKey(element): string {
        return element.getAttribute('data-bftext');
    }

    //Get resourceId
    private getResourceId(element): string {
        return element.getAttribute('data-bfimage');
    }

    private getCalculatedStyle(el, propName) {
        return el.currentStyle ? el.currentStyle[propName] : this.artboardIframe?.nativeElement?.contentWindow.getComputedStyle ? this.artboardIframe?.nativeElement?.contentWindow.document.defaultView.getComputedStyle(el, null).getPropertyValue(propName) : null;
    }

    private uploadResource(id: string, element: HTMLElement) {
        this.uploadDialogService.show(false, 'image/*', 'Replace image in this version', 'artboard', id, element).then((response: BFDialogResponse<FileModel[]>) => {
            if (!response.cancel) {
                this.textService.addFileToCurrentTranslation(response.data[0], id);
            }
        });
    }

    private openInspector(scribe: ScribeWrapper): void {

        //Already opened on this element
        if (this.inspector && this.inspector.instance.scribe === scribe) {
            return;
        }

        this.closeInspector(scribe);
        let inspector = this.bfComponentContainer.attach(InspectorComponent, this.bfMaterial.rootViewContainerRef);

        inspector.instance.show(scribe, this.iframe).then((result: boolean) => {
            //If another inspector has already been opened, don't close it...
            if (this.inspector === inspector)
                this.closeInspector();
        });
        this.inspector = inspector;
    }

    private closeInspector(scribe?: ScribeWrapper): void {
        if (this.inspector) {
            this.bfComponentContainer.detach(this.inspector);
            this.inspector = null;
        }
    }

    private setIndexes() {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            if (landingPage.originalTranslation.texts) {
                for (let i = 0; i < landingPage.originalTranslation.texts.length; i++) {
                    let text = landingPage.originalTranslation.texts[i];
                    this.each((scribe) => {
                        scribe.textInput.setAttribute('data-bfnumber', '' + (i + 1));
                    }, text.key);
                }
            }
        });
    }

    private highlightByKey(key?: String) {
        for (let element of this.getTextElements()) {
            if (key && this.getKey(element) === key) {
                element.classList.add('bfActive')
            }
            else {
                element.classList.remove('bfActive')
            }
        }
    }

    //Remove listeners.
    private destroy() {
        this.clearSelectedTextTimeout();

        //Iframe load
        if (this.onIframeLoad) {
            this.onIframeLoad();
        }

        //Resource subscribtion
        if (this.onResourceChange) {
            this.onResourceChange.unsubscribe();
        }

        //Stop subscribing to text service
        if (this.onTextChange)
            this.onTextChange.unsubscribe();

        //Stop subscribing to design service
        if (this.onSelectText)
            this.onSelectText.unsubscribe();

        if (this.onIframeMouseEvent
            && this.window
            && this.window.removeEventListener) {
            this.window.removeEventListener('click', this.onIframeMouseEvent);
            this.window.removeEventListener('mousedown', this.onIframeMouseEvent);
            this.window.removeEventListener('mouseup', this.onIframeMouseEvent);
        }

        //Safari fix (Try-catch added because Edge can't find document)
        try {
            if (this.onScroll
                && this.window
                && this.window.document
                && this.window.document.removeEventListener) {
                this.window.document.removeEventListener('scroll', this.onScroll)
            }
        } catch (e) { }

        //Remove all scribe instances
        while (this.scribeContainers.length) {
            this.scribeContainers.pop().destroy();
        }

        //Remove all image instances
        while (this.imageContainers.length) {
            let image = this.imageContainers.pop();
            image.onMouseOver();
            image.onMouseOut();
            image.onClick();
        }
    }

    private clearSelectedTextTimeout() {
        if (this.selectedTextTimeout) {
            clearTimeout(this.selectedTextTimeout);
        }
    }

    ngOnDestroy() {
        if (this.onLandingPageSaved)
            this.onLandingPageSaved.unsubscribe();

        if (this.onCurrentTranslationChanged)
            this.onCurrentTranslationChanged.unsubscribe();

        //
        this.destroy();
    }
}

//Keeping references for cleaning up all events later
export class ScribeContainer {
    public onChange: any;
    public onClick: any;
    public onFocus: any;
    public onBlur: any;
    public scribe: ScribeWrapper;

    public destroy() {
        if (this.onClick)
            this.scribe.textInput.removeEventListener('mousedown', this.onClick)
        if (this.onChange)
            this.onChange.unsubscribe();
        if (this.onBlur)
            this.onBlur.unsubscribe();
        if (this.onFocus)
            this.onFocus.unsubscribe();
        if (this.scribe)
            this.scribe.destroy();
    }
}

export class ArtboardSize {
    constructor(public name: string, public cssLabel: string) {

    }
}
