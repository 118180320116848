import {ApiService} from "shared/services/api.service";
import {Injectable} from "@angular/core";
import {ExternalTranslationModel} from "externalTranslation/externalTranslation.model";

@Injectable({ providedIn: 'root' })
export class TranslatorService {
    constructor(private apiService: ApiService) {}
    public translatorId: string;

    public init(translatorId: string):void {
        this.translatorId = translatorId;
    }

    public getTranslations():Promise<ExternalTranslationModel[]> {
        var promise = new Promise<ExternalTranslationModel[]>(resolve=>{
            this.apiService.get<ExternalTranslationModel[]>('externalTranslation', this.translatorId, true)
                .then((translations) => {
                    var externalTranslations: ExternalTranslationModel[] = [];
                   translations.forEach((et: any) => {
                        externalTranslations.push( new ExternalTranslationModel().deserialize(et) );
                    });

                    resolve(externalTranslations);
                });
        });
        return promise;
    }

}
