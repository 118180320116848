import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'bfCapitalize',
    standalone: true
})
export class BFCapitalizePipe implements PipeTransform {

    /**
     * Make first letter of a string capital
     * @param value String to make lower case
     * @param forceLowerCase Pass true to force rest of the string lowercase
     */
    public static capitalize(value: string = '', forceLowerCase?: boolean): string {
        if (value) {
            if (forceLowerCase) {
                value = value.toLowerCase();
            }

            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }

    /**
     * Make first letter of a string capital
     * @param value String to make lower case
     * @param forceLowerCase Pass true to force rest of the string lowercase
     */
    public transform(value: string, forceLowerCase?: boolean): string {
        return BFCapitalizePipe.capitalize(value, forceLowerCase);
    }
}
