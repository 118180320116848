<div class="bfSwatches">

    
    <div class="bfSwatches__category" *ngFor="let category of categories">
        <div class="bfSwatches__categoryName" *ngIf="category.name">
            {{category.name}}
        </div>
        <div class="bfSwatches__colors" *ngIf="category.colors">
            <div    class="bfSwatches__color" 
                    [style.backgroundColor]="c.color" 
                    *ngFor="let c of category.colors; let i = index;"
                    [ngClass]="{'bfSwatches__color--active': toRgba(c.color) === rgbaColor, 'bfSwatches__color--last': i === category.colors.length - 1 }"
                    (mousedown)="selectColor(c.color, true, $event)">
                    <i class="bf-icon bf-icon-checkmark"></i>
            </div>
        </div>
        <div class="bfSwatches__column" *ngFor="let column of category.columns; let columnIndex = index;">
            <div    class="bfSwatches__color" 
                    [style.backgroundColor]="c.color" 
                    *ngFor="let c of column.colors; let i = index;"
                    [ngClass]="{'bfSwatches__color--active': toRgba(c.color) === rgbaColor, 'bfSwatches__color--last': i === column.colors.length - 1 || columnIndex === category.columns.length - 1 }"
                    (mousedown)="selectColor(c.color, true, $event)">
                    <i class="bf-icon bf-icon-checkmark"></i>
            </div>
        </div>
        
    </div>
</div>