import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { BFPositionService, BFUtilsService } from '../../services/index';

import 'jquery';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'bfPopover',
    templateUrl: './bfPopover.component.html',
    styleUrls: ['./bfPopover.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class BFPopoverComponent implements OnDestroy {
    public options: any = {};
    public position: any = {};

    private clickEventListener: Function;
    private scrollEventListener: Function;
    private activeElement: Element;
    private popOverElement: HTMLElement;

    protected callback: (x: boolean) => any;

    constructor(
        private elementRef: ElementRef,
        private positionService: BFPositionService,
        private Renderer2: Renderer2
    ) {
        this.options.placement = 'right';
        this.position.left = 200;
        this.position.top = 200;
    }

    public open(element: Element, placement: string): Promise<boolean> {
        this.activeElement = element;
        this.popOverElement = this.elementRef.nativeElement.children[0];

        setTimeout(() => {
            this.popOverElement.style.display = 'block';
            this.options.placement = placement;

            this.positionPopover();

            this.clickEventListener = this.Renderer2.listen('document', 'click', (event: any) => {
                if (!BFUtilsService.isElementInElement(event.target, this.popOverElement) && !BFUtilsService.elementOrAncestorHasClass(event.target, 'bfPopover--anchor')) {
                    this.close();
                }
            });

            if (this.popOverElement.querySelector('.bfPopover__content')) {
                this.scrollEventListener = this.Renderer2.listen(this.popOverElement.querySelector('.bfPopover__content'), 'mousewheel', (event: any) => {
                    let delta = event.wheelDelta || -event.detail;

                    this.popOverElement.querySelector('.bfPopover__content')!.scrollTop += (delta < 0 ? 1 : -1) * 20;
                    event.preventDefault();
                });
            }
        });

        let promise = new Promise<boolean>(resolve => {
            this.callback = resolve;
        });

        return promise;
    }

    public move(element: Element): Promise<boolean> {

        this.activeElement = element;
        this.popOverElement.classList.add('bfPopover--transition');

        setTimeout(() => {
            this.positionPopover();
        })

        let promise = new Promise<boolean>(resolve => {
            this.callback = resolve;
        });

        return promise;
    }

    protected positionPopover(): void {
        this.position = this.positionService.smartPosition(
            this.activeElement,
            this.popOverElement,
            this.options.placement,
            true
        );
    }

    public close(): void {
        this.callback(false);
    }

    ngOnDestroy() {
        //Remove event listener for clicks
        if (this.clickEventListener)
            this.clickEventListener();

        //Remove listener for scroll
        if (this.scrollEventListener)
            this.scrollEventListener();
    }
}
