import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BFMaterial {
    public rootViewContainerRef: ViewContainerRef;

    constructor() { }

    public init(rootViewContainerRef: ViewContainerRef): void {
        this.rootViewContainerRef = rootViewContainerRef;
    }
}
