<div class="showcase">

    <!--HEADER-->
    <header class="showcaseHeader__header header"
            #header>

        <i class="logo"></i>

        <div class="showcaseHeader__center">

            <!--VERSIONS-->
            <div class="showcaseHeader__left">
                <div class="header__versionPicker showcaseHeader__versionPicker"
                     *ngIf="textService.currentTranslation"
                     bfTooltip="Change to another version or manage all versions."
                     bfTooltipPlacement="bottom"
                     [bfMenu]="translationsMenuConfig">
                    <ui-flag size="tiny" [culture]="textService.currentTranslation.culture"></ui-flag>
                    {{textService.currentTranslation.name}}&nbsp;
                    <span class="showcaseHeader__status {{getStatusClassName(textService.currentTranslation)}}"></span>&nbsp;
                    <i class="bf-icon bf-icon-caret-down"></i>
                </div>
            </div>

            <div class="showcaseHeader__right">

                <!--CLOSE TOP BAR (MOBILE)-->
                <ui-button
                    id="interaction-mobilebutton-landingpage-btn"
                    type="default"
                    ui-theme="minimal"
                    svgIcon="close"
                    (click)="hideTopBar()">
                </ui-button>

                <div class="showcaseHeader__rightIcon showcaseHeader--desktopButton"
                     [bfMenu]="actionMenuConfig">
                    <i class="bf-icon bf-icon-more-standing"></i>
                </div>

                <div class="showcaseHeader__rightIcon showcaseHeader--desktopButton"
                     (click)="toggleInfo($event, true)">
                    <i class="bf-icon bf-icon-info"></i>
                </div>

                <div class="showcaseHeader__rightIcon showcaseHeader--desktopButton"
                    (click)="toggleQRCode($event)"
                    bfTooltip="QR code for mobile preview"
                    bfTooltipPlacement="bottom">
                    <i class="bf-icon bf-icon-qrcode"></i>
                </div>

                <span class="showcaseHeader__divider showcaseHeader--desktopButton"></span>

                <!--VIEWPORTS-->
                <div class="showcaseHeader__sizePicker showcaseHeader--desktopButton"
                     bfTooltip="Change the size of your artboard"
                     bfTooltipPlacement="bottom"
                     [bfMenu]="sizeMenuConfig">
                    <span>{{designService.artboardSize.name}}</span>&nbsp;
                    <i class="bf-icon bf-icon-caret-down"
                       style="margin-left: 2px;"></i>
                </div>

            </div>
        </div>

        <div [ngClass]="{ 'isNotLoggedIn': !(landingPage && landingPage.userLoggedIn) }"
             class="showcaseInfo"
             *ngIf="textService.currentTranslation && showInfo"
             #info>
            <div class="showcaseHeader__center" style="flex-direction: column;">
                <div class="showcaseInfo__arrow"></div>
                <div class="showcaseInfo__arrowBorder"></div>
                <div class="showcaseInfo__leftRight">
                    <div class="showcaseInfo__left">
                        <div class="showcaseInfo__content">
                            <div class="showcaseInfo__row1">
                                <h1 class="showcaseInfo__landingPageName">{{landingPage.name}}</h1>
                            </div>
                            <div class="showcaseInfo__row2">
                                <span class="showcaseInfo__date">Created <span class="date">{{landingPage.created | formatDate }}</span></span>
                                <span class="showcaseInfo__date">Modified <span class="date">{{landingPage.updated | formatDate }}</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="showcaseInfo__right">
                        <div class="showcaseInfo__content">
                            <div class="showcaseInfo__row1">
                                <div class="showcaseInfo__versionMargin">
                                    <div class="showcaseInfo__version">
                                        <ui-flag size="tiny" [culture]="textService.currentTranslation.culture"></ui-flag>&nbsp;
                                        {{textService.currentTranslation.name | bfEllipsis: 60 : false }}&nbsp;
                                        <span class="showcaseHeader__status {{getStatusClassName(textService.currentTranslation)}}"></span></div>
                                </div>
                            </div>
                            <div class="showcaseInfo__row2">
                                <div class="showcaseInfo__iconAndPublicUrl" *ngIf="publicUrl">
                                    <i class="bf-icon bf-icon-link"></i>
                                    <a class="showcaseInfo__publicUrl" [href]="publicUrl" target="_blank">{{publicUrl | ellipsisMiddle: 70}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="showcaseBody"
         #body>
        <artboard [isShowcase]="true"></artboard>
    </div>
</div>
