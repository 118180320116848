import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    constructor() {

    }

    public setTheme(theme: Themes):void{
        if(theme == Themes.Dark){
            window.document.querySelector('body').classList.add('app--darkTheme');
        } else {
            window.document.querySelector('body').classList.remove('app--darkTheme');
        }
    }
}

export enum Themes {
    Default = 1,
    Dark = 2
}
