import { Component, Input, ViewChild, ElementRef, Renderer2, OnDestroy, ChangeDetectionStrategy, HostBinding, HostListener } from '@angular/core';
import { BFPositionService, BFUtilsService, BFDialogResponse } from '../../index';
import { BFCapitalizePipe } from '../../pipes/capitalize/bfCapitalize.pipe';
import { BFNotificationPlacement, BFNotificationType } from './bfNotification.service';
import { NgFor, NgClass, NgIf, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'bfNotificationComponent',
    templateUrl: 'bfNotification.component.html',
    styleUrls: ['bfNotification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgClass, NgIf, LowerCasePipe]
})
export class BFNotificationComponent implements OnDestroy {
    public placement: BFNotificationPlacement;
    public type: BFNotificationType;
    // public text: string;
    public texts: string[];

    public icon: undefined | string;

    @HostBinding('class.bfNotification') private mainClass: boolean = true;
    @HostBinding('class.bfNotification--typeError') private typeErrorClass: boolean;
    @HostBinding('class.bfNotification--typeSuccess') private typeSuccessClass: boolean;
    @HostBinding('class.bfNotification--typeWarning') private typeWarningClass: boolean;
    @HostBinding('class.bfNotification--typeInfo') private typeInfoClass: boolean;

    @HostBinding('class.bfNotification--positionBottom') private positionBottomClass: boolean;
    @HostBinding('class.bfNotification--positionTop') private positionTopClass: boolean;

    private callback: () => any;
    private timer: any;

    constructor(private capitalizePipe: BFCapitalizePipe) { }

    public initiate(text: string | string[], type: BFNotificationType, placement: BFNotificationPlacement, delay: number, icon?: string): Promise<any> {
        let promise: Promise<void> = new Promise(resolve => {
            this.callback = resolve;
        });

        // If input is array
        if (Array.isArray(text)) {
            this.texts = text.map(t => this.capitalizePipe.transform(t));
        }
        else {
            this.texts = [this.capitalizePipe.transform(text as string)];
        }

        this.icon = icon;

        this.placement = placement;
        this.type = type;

        if (delay > 0) {
            this.timer = setTimeout(() => {
                this.close();
            }, delay);
        }

        this.updateClasses();

        return promise;
    }

    public removeNotification(notification: string): void {
        if (this.texts.length === 1) {
            this.close();
        }
        else {
            this.texts = this.texts.filter(text => text !== notification);
        }
    }

    public close(): void {
        this.clearTimer();
        this.callback();
    }

    public ngOnDestroy(): void {
        this.clearTimer();
    }

    private updateClasses(): void {
        this.typeErrorClass = this.type === 'error';
        this.typeSuccessClass = this.type === 'success';
        this.typeWarningClass = this.type === 'warning';
        this.typeInfoClass = this.type === 'info';

        this.positionBottomClass = this.placement === 'bottom';
        this.positionTopClass = this.placement === 'top';
    }

    private clearTimer(): void {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
    }
}
