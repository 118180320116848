import {EventEmitter, Injectable} from "@angular/core";
import {TextModel} from "shared/models/text.model";
import {ArtboardSize} from "design/views/design/components/designEditor/artboard/artboard.component";

@Injectable({ providedIn: 'root' })
export class DesignService {

    public selectedTextChange: EventEmitter<TextModel> =
    new EventEmitter<TextModel>();
    public artboardSize: ArtboardSize;
    public artboardSizes: Array<ArtboardSize>
    public selectedText: TextModel;

    constructor() {
        this.artboardSizes = new Array<ArtboardSize>(
            new ArtboardSize("Full", "full"),
            new ArtboardSize("1024px", "desktop-1024"),
            new ArtboardSize("1280px", "desktop-1280"),
            new ArtboardSize("1440px", "desktop-1440"),
            new ArtboardSize("1920px", "desktop-1920"),
            new ArtboardSize("iPad", "ipad"),
            new ArtboardSize("iPhone 5", "iphone-5"),
            new ArtboardSize("iPhone 6", "iphone-6"),
            new ArtboardSize("iPhone 6 Plus", "iphone-6-plus"),
            new ArtboardSize("Galaxy S5", "galaxy-s5"),
            new ArtboardSize("Nexus 5X", "nexus-5x"),
        );
        this.artboardSize = this.artboardSizes[0];
    }

    public selectText(text?: TextModel, from: 'sidebar' | 'canvas' = 'canvas'): void {
        if(text !== this.selectedText) {
            this.selectedText = text;
            if (text) {
                text.from = from;
            }
            this.selectedTextChange.emit(text);
        }
    }

    public setSize(size: ArtboardSize): void {
        this.artboardSize = size;
    }
}

