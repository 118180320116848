import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'filter',
    standalone: true
})
export class FilterPipe implements PipeTransform {
    transform(value: any, query: string, property: string): any {
        if(!query || !property)
            return value;

        let filter = query.toLocaleLowerCase(); 
        let getPropertyByString = (o: any, s: string) =>{
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        }

        try{
            return filter ? value.filter(item => getPropertyByString(item, property).toLocaleLowerCase().indexOf(filter) != -1) : value; 
        }catch(e){
            return value;
        }
    }
}
