import { BFMenuItem } from './bfMenuItem'

export class BFMenuConfig {
    public menuItems: BFMenuItem[];
    public options: BFMenuConfigOptions;

    constructor(menuItems?: BFMenuItem[], options?: BFMenuConfigOptions) {
        this.menuItems = menuItems || [];
        this.options = options || new BFMenuConfigOptions();
    }

    public getMenuItemByLabel(label: string): BFMenuItem | undefined {
        return this.menuItems.find(item => item.label === label);
    }
}

export class BFMenuConfigOptions {
    public placement = 'bottom';
    public class = '';
    public closeOnSelect = true;
    public width = 0;
    public useUnsafeHtml = false;
    public offset: any = {
        left: 0,
        top: 0
    }
    public showArrow: boolean = true;

    constructor(placement?: string, offset?: any, showArrow: boolean = false) {
        if (placement) {
            this.placement = placement;
        }
        if (offset) {
            this.offset.top = offset.top;
            this.offset.left = offset.left;
        }
        this.showArrow = showArrow;
    }
}