import { Injectable, OnDestroy } from '@angular/core';
import { BFDialogResponse } from '../../index'
import { BFMaterial } from '../../bfMaterial'
import { BFDialogContainer } from '../../containers/bfDialog.container'
import { BFConfirmDialogComponent } from './bfConfirmDialog.component';

export enum BFConfirmDialogResponse {
    OK = 1,
    Cancel = 2
};

@Injectable({ providedIn: 'root' })
export class BFConfirmDialogService implements OnDestroy {
    constructor(private dialogContainer: BFDialogContainer,
                private bfMaterial: BFMaterial){

    }

    public show(text: string,
                subText: string = '',
                confirmText: string = 'Ok',
                cancelText: string = 'Cancel',
                confirmAction?: Function): Promise<BFDialogResponse<boolean>> {

        const confirmDialog = this.dialogContainer.attach(BFConfirmDialogComponent);
        return confirmDialog.instance.initiate(text, subText, confirmText, cancelText, confirmAction).then((response:BFDialogResponse<boolean>) => {
            this.dialogContainer.detach(confirmDialog);
            return response;
        });
    }

    ngOnDestroy() {

    }

}
