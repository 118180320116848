import { Component, ElementRef, Inject, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import moment from 'moment';
import { NgClass, NgIf, NgFor } from '@angular/common';

export interface IBFDatePickerOptions {
    minDate?: Date;
    maxDate?: Date;
    initialDate?: Date;
    firstWeekdaySunday?: boolean;
    format?: string;
    isSpan?: boolean;
    timePicker?: boolean;
    closeOnPick?: boolean;
}

export class BFDatePickerOptions {
    public minDate?: Date;
    public maxDate?: Date;
    public initialDate?: Date;
    public firstWeekdaySunday?: boolean;
    public format?: string;
    public isSpan?: boolean;
    public timePicker?: boolean;
    public closeOnPick?: boolean;

    constructor(obj?: IBFDatePickerOptions) {
        this.minDate = obj && obj.minDate ? obj.minDate : undefined;
        this.maxDate = obj && obj.maxDate ? obj.maxDate : undefined;
        this.initialDate = obj && obj.initialDate ? obj.initialDate : undefined;
        this.firstWeekdaySunday = obj && obj.firstWeekdaySunday ? obj.firstWeekdaySunday : false;
        this.format = obj && obj.format ? obj.format : 'YYYY-MM-DD';
        this.isSpan = (obj && typeof obj.isSpan === 'boolean') ? obj.isSpan : true;
        this.timePicker = (obj && typeof obj.timePicker === 'boolean') ? obj.timePicker : false;
        this.closeOnPick = (obj && typeof obj.closeOnPick === 'boolean') ? obj.closeOnPick : false;
    }
}

export interface CalendarDate {
    day?: number;
    month?: number;
    year?: number;
    enabled?: boolean;
    today?: boolean;
    selected?: {
        first?: boolean,
        last?: boolean,
        between?: boolean
    };
    momentObj?: moment.Moment;
}

export const CALENDAR_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BFDatePickerComponent),
    multi: true
};

@Component({
    selector: 'bfDatePicker',
    templateUrl: 'bfDatePicker.component.html',
    styleUrls: ['./bfDatePicker.component.scss'],
    providers: [CALENDAR_VALUE_ACCESSOR],
    standalone: true,
    imports: [NgClass, NgIf, NgFor]
})
export class BFDatePickerComponent implements ControlValueAccessor, OnInit {
    @Input() public options: BFDatePickerOptions;
    @Output() public change = new EventEmitter<[Date, Date]>();

    private selectedDateSpan: Date[];
    private pendingDateSpan: Date[];
    public currentDate: moment.Moment;
    public days: CalendarDate[];
    public years: number[];
    public yearPicker: boolean;

    public showTimePicker: boolean = false;
    private tempDate: moment.Moment;
    public hours: number[] = new Array(24);

    private minDate: moment.Moment | undefined;
    private maxDate: moment.Moment | undefined;

    private onTouchedCallback: () => void = () => { };
    private onChangeCallback: (_: any) => void = () => { };

    constructor(@Inject(ElementRef) public el: ElementRef) {
        this.currentDate = moment();
        this.options = this.options || {};
        this.days = [];
        this.years = [];
        this.selectedDateSpan = [];
    }

    get value(): Date[] {
        return this.selectedDateSpan;
    }

    set value(date: Date[]) {
        if (!date) { return; }
        this.selectedDateSpan = date;
    }

    ngOnInit() {
        this.options = new BFDatePickerOptions(this.options);

        if (this.options.initialDate instanceof Date && !this.value) {
            this.currentDate = moment(this.options.initialDate);
            this.selectDate(null, this.currentDate, false);
        }

        if (this.options.minDate instanceof Date) {
            this.minDate = moment(this.options.minDate);
        } else {
            this.minDate = undefined;
        }

        if (this.options.maxDate instanceof Date) {
            this.maxDate = moment(this.options.maxDate);
        } else {
            this.maxDate = undefined;
        }

        this.generateYears();
        this.generateCalendar();
    }

    leaveTrack(event: MouseEvent, date: moment.Moment) {
        // event.preventDefault();
        if (!this.options.isSpan) {
            return;
        }
        if (!this.selectedDateSpan[0]) {
            return;
        }
        if (this.selectedDateSpan[0] && this.selectedDateSpan[1]) {
            return;
        }
        this.trackDate(date);
    }

    get selecting() {
        return this.options.isSpan && this.selectedDateSpan[0] && !this.selectedDateSpan[1];
    }

    trackDate(date: moment.Moment) {
        if (date.isAfter(this.selectedDateSpan[0])) {
            this.pendingDateSpan = [this.selectedDateSpan[0], date.toDate()];
        }
        else {
            this.pendingDateSpan = [date.toDate(), this.selectedDateSpan[0]];
        }
        // this.generateCalendar(/*leaveTrack*/ true);
    }

    generateCalendar(leaveTrack = false) {
        let date = moment(this.currentDate);
        let month = date.month();
        let year = date.year();
        let n = 1;
        let firstWeekDay = (this.options.firstWeekdaySunday) ? date.date(2).day() : date.date(1).day();

        if (firstWeekDay !== 1) {
            n -= (firstWeekDay + 6) % 7;
        }

        this.days = [];
        const dateSpan = leaveTrack ? this.pendingDateSpan : this.selectedDateSpan;
        let selectedStartDate = dateSpan[0] ? moment(dateSpan[0]) : undefined;
        let selectedEndDate = dateSpan[1] ? moment(dateSpan[1]) : undefined;

        for (let i = n; i <= date.endOf('month').date(); i += 1) {
            let currentDate = moment(`${i}.${month + 1}.${year}`, 'DD.MM.YYYY');
            let today = moment().isSame(currentDate, 'day') && moment().isSame(currentDate, 'month');
            let between = false;
            let first = false;
            let last = false;

            if (this.options.isSpan) {
                if (selectedStartDate && selectedEndDate) {
                    between = currentDate.isBetween(selectedStartDate, selectedEndDate, 'day');
                    first = currentDate.isSame(selectedStartDate, 'day');
                    last = currentDate.isSame(selectedEndDate, 'day');
                }
                else if (selectedStartDate) {
                    first = selectedStartDate.isSame(currentDate, 'day');
                }
            }
            else {
                first = last = (selectedStartDate && selectedStartDate.isSame(currentDate, 'day')) || false;
            }

            let betweenMinMax = true;
            if (this.minDate !== undefined) {
                if (this.maxDate !== undefined) {
                    betweenMinMax = currentDate.isBetween(this.minDate, this.maxDate, 'day', '[]');
                } else {
                    betweenMinMax = !currentDate.isBefore(this.minDate, 'day');
                }
            } else {
                if (this.maxDate !== undefined) {
                    betweenMinMax = !currentDate.isAfter(this.maxDate, 'day');
                }
            }

            let day: CalendarDate = {
                day: i > 0 ? i : undefined,
                month: i > 0 ? month : undefined,
                year: i > 0 ? year : undefined,
                enabled: i > 0 ? betweenMinMax : false,
                today: i > 0 && today,
                selected: {
                    first: i > 0 && first,
                    last: i > 0 && last,
                    between: i > 0 && between,
                },
                momentObj: currentDate
            };

            this.days.push(day);
        }
    }

    handleDateClick(event: MouseEvent, date: moment.Moment, emit: boolean = true): void {
        if (this.options.timePicker) {
            this.tempDate = date;
            this.showTimePicker = true;
        } else {
            this.selectDate(event, date, emit);
        }
    }

    selectDate(event: MouseEvent | null, date: moment.Moment, emit = true, leaveTrack = false) {
        if (event) event.stopPropagation();

        setTimeout(() => {
            let hasChanged = false;

            if (this.options.isSpan) {

                // if from date is undefined
                if (!this.selectedDateSpan[0]) {
                    this.selectedDateSpan = [date.toDate()];

                    // if to date is undefined
                } else if (!this.selectedDateSpan[1]) {
                    this.selectedDateSpan = [this.selectedDateSpan[0], date.toDate()];

                    // if first date is after second date, reverse array
                    if (moment(this.selectedDateSpan[0]).isAfter(moment(this.value[1]))) {
                        this.selectedDateSpan = this.selectedDateSpan.reverse();
                    }
                    hasChanged = true;

                    // if both dates were defined, you should restart the selection process.
                } else if (this.selectedDateSpan[0] && this.selectedDateSpan[1]) {
                    this.selectedDateSpan = [date.toDate()];
                }

            } else {
                this.selectedDateSpan = [date.utc().toDate()];
                hasChanged = true;
            }
            if (hasChanged) {
                if (emit) {
                    this.onChangeCallback(this.selectedDateSpan);
                }
                this.change.emit();
            }

            this.generateCalendar(leaveTrack);
        });
    }

    selectTime(e: MouseEvent, hour: number, emit: boolean = true) {
        this.tempDate.hour(hour);
        if (!this.options.closeOnPick) {
            this.showTimePicker = false;
        }
        this.selectDate(e, this.tempDate, emit);
    }

    selectYear(e: MouseEvent, year: number) {
        e.preventDefault();

        setTimeout(() => {
            let date = this.currentDate.year(year);
            this.selectedDateSpan = [date.toDate()];
            this.yearPicker = false;
            this.generateCalendar();
        });
    }

    generateYears() {
        let date = (this.minDate == undefined ? undefined : this.minDate.clone()) || moment().year(moment().year() - 15);
        let toDate = (this.maxDate == undefined ? undefined : this.maxDate.clone()) || moment().year(moment().year() + 40);
        let years = toDate.year() - date.year();

        for (let i = 0; i < years; i++) {
            this.years.push(date.year());
            date.add(1, 'year');
        }
    }

    writeValue(date: Date[]) {
        if (!date) { return; }
        this.selectedDateSpan = date;

        this.generateCalendar();
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    prevMonth() {
        this.currentDate = this.currentDate.subtract(1, 'month');
        this.generateCalendar();
    }

    nextMonth() {
        this.currentDate = this.currentDate.add(1, 'month');
        this.generateCalendar();
    }

    today() {
        this.currentDate = moment();
        this.selectDate(null, this.currentDate);
    }

    openYearPicker() {
        setTimeout(() => this.yearPicker = true);
    }

    clear() {
        this.selectedDateSpan = [];
        this.onChangeCallback(this.selectedDateSpan);
    }

}
