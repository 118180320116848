import { Directive, ElementRef, Input, Renderer2, OnDestroy, OnInit, Output, OnChanges, EventEmitter, ComponentRef, SimpleChanges } from '@angular/core';

import { BFMaterial } from '../../bfMaterial';
import { BFComponentContainer } from '../../containers/bfComponent.container';

import { BFMenuConfig } from './bfMenuConfig';
import { BFMenuItem } from './bfMenuItem';
import { BFMenuComponent } from './list/bfMenu.list.component';

@Directive({
    selector: '[bfMenu]',
    exportAs: 'bfMenu', //the name of the variable to access the Directive
    outputs: ['select'],
    standalone: true
})

export class BFMenuDirective implements OnDestroy, OnInit, OnChanges {
    @Input('bfMenu') menuConfig: BFMenuConfig;
    // @Output('bfMenuOpen') public menuOpen: EventEmitter<any> = new EventEmitter<any>();

    protected select: EventEmitter<BFMenuItem> = new EventEmitter<BFMenuItem>();

    protected element: any;
    protected opened: boolean;
    protected menuComponentRef: ComponentRef<BFMenuComponent>;
    protected closeEventEmitter: EventEmitter<any> = new EventEmitter<any>();

    // Listeners
    protected onClick: Function;

    constructor(
        protected elementRef: ElementRef,
        protected Renderer2: Renderer2,
        protected componentContainer: BFComponentContainer,
        protected bfMaterial: BFMaterial
    ) { }

    public ngOnInit(): void {
        this.onClick = this.Renderer2.listen(this.elementRef.nativeElement, 'click', this.toggleMenu.bind(this));
    }
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.menuConfig) {
            if (this.menuComponentRef && this.menuComponentRef.instance) {
                this.menuComponentRef.instance.menuConfig = this.menuConfig;
            }
        }
    }

    private toggleMenu(event: MouseEvent): void {
        if (!this.opened) {
            this.open(this.elementRef.nativeElement);
        } else {
            this.close();
        }
        event.preventDefault();
    }

    public open(positionOrElement: any): void {
        if (this.opened || !this.menuConfig || !this.menuConfig.menuItems || this.menuConfig.menuItems.length === 0) {
            return;
        }

        this.menuComponentRef = this.componentContainer.attach(BFMenuComponent, this.bfMaterial.rootViewContainerRef);
        this.menuComponentRef.instance.initiate(this.menuConfig, positionOrElement).then((item: BFMenuItem) => {

            this.select.emit(item);

            if (this.menuConfig.options.closeOnSelect) {
                this.close();
            }
        }).catch((value: any) => {
            this.close();
        });
        this.opened = true;
        //this.menuOpen.emit();
    }

    public close(): void {
        this.opened = false;
        this.closeEventEmitter.emit();
        this.componentContainer.detach(this.menuComponentRef);
    }

    // Remove listeners
    public ngOnDestroy(): void {
        this.onClick();
        this.close();
    }
}