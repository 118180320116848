<div class="bfColorPicker" #dialogPopup>

     <!-- MAIN COLOR PICKER AREA -->
    <div [slider] 
         [style.background-color]="hueSliderColor" 
         [rgX]="1"
         [rgY]="1"
         [style.height.px]="config.height"
         (newValue)="setSaturationAndBrightness($event)"
         (startEditing)="onStartEditing()"
         (stopEditing)="onStopEditing()"
         class="bfColorPicker__saturationLightness"
         #saturationSlider>
        <div [style.left.px]="slider.s" 
             [style.top.px]="slider.v" 
             class="bfColorPicker__cursor"></div>
    </div>

    <div class="bfColorPicker__colorSliders">

        <!-- SELECTED COLOR -->
        <div class="bfColorPicker__colorSlidersLeft" *ngIf="config.showSelectedColor">
            <div class="bfColorPicker__selectedColorBackground"></div>
            <div [style.background-color]="selectedColor" 
                 class="bfColorPicker__selectedColor"></div>
        </div>

        <!-- HUE AND ALPHA SLIDERS -->
        <div class="bfColorPicker__colorSlidersRight">
            <!--<div *ngIf="!config.showAlphaSlider" 
                 style="height: 18px;"></div>-->
            
            <!-- HUE SLIDER -->
            <div [hidden]="!config.showHueSlider" 
                 [slider] 
                 [rgX]="1" 
                 (newValue)="setHue($event)"
                 (startEditing)="onStartEditing()"
                 (stopEditing)="onStopEditing()"
                 class="bfColorPicker__hue"
                 #hueSlider>
                <div [style.left.px]="slider.h" 
                     class="bfColorPicker__cursor bfColorPicker__cursor--filled"></div>
            </div>
            
            <!-- ALPHA SLIDER -->
            <div [hidden]="!config.showAlphaSlider" 
                 [slider] 
                 [style.background-color]="alphaSliderColor" 
                 [rgX]="1" 
                 (newValue)="setAlpha($event)"
                 (startEditing)="onStartEditing()"
                 (stopEditing)="onStopEditing()"
                 class="bfColorPicker__alpha" 
                 #alphaSlider>
                <div [style.left.px]="slider.a" 
                     class="bfColorPicker__cursor bfColorPicker__cursor--filled"></div>
            </div>
        </div>
    </div>

    <!-- TEXT OUTPUT -->
    <div class="bfColorPicker__colorFormat" *ngIf="config.showColorTextInput">

        <!-- RGBA -->
        <div [hidden]="format!=1" 
            class="bfColorPicker__colorFormatWrapper">
            <div class="bfColorPicker__colorTexts">
                <input [colorText] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setR($event)" [value]="rgbaText.r"/>
                <input [colorText] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setG($event)" [value]="rgbaText.g"/>
                <input [colorText] type="number" pattern="[0-9]*" min="0" max="255" [rg]="255" (newValue)="setB($event)" [value]="rgbaText.b"/>
                <input *ngIf="cpAlphaChannel!=='disabled'" [colorText] type="number" pattern="[0-9]+([\.,][0-9]{1,2})?" min="0" max="1" step="0.1" [rg]="1" (newValue)="setAlpha($event)" [value]="rgbaText.a"/>
            </div>
            <div class="bfColorPicker__colorLabels">
                <div>R</div><div>G</div><div>B</div><div *ngIf="cpAlphaChannel!=='disabled'" >A</div>
            </div>
        </div>

        <!-- HEX -->
        <div [hidden]="format!=0"
            class="bfColorPicker__colorFormatWrapper">
            <div class="bfColorPicker__colorTexts">
                <input [colorText] (newValue)="setColorFromString($event)" [value]="hexText"/>
            </div>
            <div class="bfColorPicker__colorLabels">
                <div>Hex</div>
            </div>
        </div>

        <div (mousedown)="formatPolicy($event)" 
             class="bfColorPicker__colorFormatSwitcher"></div>
    </div>
</div>