import {Pipe, PipeTransform} from '@angular/core';

var parser = new DOMParser;

function decodeHtml(html: string) {
    var dom = parser.parseFromString(
        '<!doctype html><body>' + html,
        'text/html');
    return dom.body.textContent;
}

@Pipe({
    name: 'stripTags',
    standalone: true
})
export class StripTagsPipe implements PipeTransform {
    transform(text: string) {
        if(!text) return '';
        return decodeHtml(text)
            .replace(/(<\/div><div>)/ig, " ") //Make sure enter strokes from text editor looks ok
            .replace(/(<([^>]+)>)/ig, "") //Remove all tags
            .replace(/\s+/g, " "); //Make all kinds of spacing to a single space
    }
}