<!--LANGUAGE-->
<td class="row-language" *ngIf="!translation?.isEditing && translation?.translation?.name && translation?.translation?.culture">
    <ui-flag size="tiny" [culture]="translation.translation.culture"></ui-flag>
    <span bfTooltip="{{ translation.translation.name }}">{{ translation.translation.name | ellipsisMiddle: 30}}</span>
</td>

<!--TRANSLATOR (for new translations)-->
<td *ngIf="showSendForTranslation && translation?.isNew && !translation?.isEditing">

    <!--note that this is a direct selector for the transltor as opposed to the selector in "edit mode".
        that selector binds to a temporary value so it can be canceled-->
    <bfSelect *ngIf="showSendForTranslation"
              [config]="translation.translatorsAndNone"
              [(selected)]="translation.translation.translator"
              (selectedChange)="translation.translation.translationState=translation.translation.translator?1:0"></bfSelect>
</td>

<!--STATUS-->
<td *ngIf="!translation?.isNew && !translation?.isEditing"
    class="bfBlockTable__subText"
    style="text-align: center;">
    <span *ngIf="isOriginal">Original</span>
    <span *ngIf="translation?.translation?.translationState && translation?.translation?.translationState === 3
    || (!translation?.translation?.deadline || (translation?.translation?.deadline > today))">
        <i class="translationRow__icon bf-icon bf-icon-email bf-icon-lg"
            *ngIf="translation.translation.translationState === 1"
            bfTooltip="Translation has been sent out for translation"></i>
        <i class="translationRow__icon bf-icon bf-icon-email-open bf-icon-lg"
            *ngIf="translation.translation.translationState === 2"
            bfTooltip="Translation is in progress"></i>
        <i class="translationRow__icon translationRow__icon--success bf-icon bf-icon-email bf-icon-lg"
            *ngIf="translation.translation.translationState === 3"
            bfTooltip="Translation is completed"></i>
    </span>
    <span *ngIf="translation.translation.translationState != 3 && translation.translation.deadline && translation.translation.deadline < today">
        <i class="translationRow__icon bf-icon bf-icon-clock bf-icon-lg"
            bfTooltip="Deadline passed"></i>
    </span>
</td>

<!--PUBLISH URL-->
<td *ngIf="!translation?.isEditing"
    class="bfBlockTable__subText">

    <!--if published, make link to publication clickable-->
    <a href="{{translation.cName}}{{translation.translation.publishSlug}}/index.html"
       *ngIf="translation.translation.publishState === 2"
       target="_blank"
       bfTooltip="{{translation.cName + translation.translation.publishSlug + '/index.html'}}">
        {{translation.cName + translation.translation.publishSlug + '/index.html' | ellipsisMiddle:50}}
    </a>

    <!--if not published, just show URL as text-->
    <div *ngIf="translation.translation.publishState != 2"
         bfTooltip="{{translation.cName + translation.translation.publishSlug + '/index.html'}}">
        {{translation.cName + translation.translation.publishSlug + '/index.html' | ellipsisMiddle:50}}
    </div>
</td>

<td class="translationRow__action bfBlockTable__action" *ngIf="!translation?.isEditing">
    <div class="translationRow__actionLeft">
        <span (click)="openEdit($event)" bfTooltip="Edit" class="translationRow__actionIconContainer">
            <i class="translationRow__actionIcon bf-icon bf-icon-edit"></i>
            &nbsp;
        </span>
        <span bfTooltip="Actions" [bfMenu]="actionMenuConfig" class="translationRow__actionIconContainer" *ngIf="!translation?.isNew && !isOriginal">
            <i class="translationRow__actionIcon bf-icon bf-icon-more-standing"></i>
        </span>
    </div>
</td>

<!--EDIT MODE-->
<td class="translationRow__edit"
    *ngIf="translation?.isEditing"
    colspan="4">
    <div style="padding: 35px 17px">

        <!--LOADER-->
        <ui-loader *ngIf="loading"></ui-loader>

        <!--FLAG AND NAME-->
        <div class="form-language" style="margin-bottom: 20px;">
            <ui-flag size="tiny" [culture]="translation.translation.culture"></ui-flag>
            <span bfTooltip="{{ translation.translation.name }}">&nbsp;{{ translation.translation.name | ellipsisMiddle: 20 }}</span>
        </div>

        <div class="translationRow__editActions" *ngIf="!translation?.isNew && !isOriginal">
            <span bfTooltip="Actions" [bfMenu]="actionMenuConfig" class="translationRow__actionIconContainer">
                <i class="translationRow__actionIcon bf-icon bf-icon-more-standing"></i>
            </span>
        </div>

        <div class="bfGrid">

            <!--TRANSLATION NAME-->
            <div class="bfGrid__col4--md gridCol">
                <label>Translation name</label>
                <input type="text"
                       spellcheck="false"
                       maxlength="250"
                       [(ngModel)]="name"
                       (ngModelChange)="checkDirty()"
                       style="width: 100%;">
            </div>

            <!--FALLBACK URL-->
            <div class="bfGrid__col6--md gridCol">
                <label>
                    Fallback URL&nbsp;
                    <bfHelpIcon text="This URL will be used when you unpublish this version"></bfHelpIcon>
                </label>
                <input
                       type="text"
                       spellcheck="false"
                       [(ngModel)]="fallbackUrl"
                       (ngModelChange)="checkDirty()"
                       style="width: 100%;"
                       placeholder="http://example.com" />
            </div>
        </div>

        <div class="clearfix"></div>

        <!--PUBLIC URL - show only if not published-->
        <div class="translationRow__editPublishUrl"
            *ngIf="translation.translation.publishState != 2">
            <label>
                Public URL&nbsp;
                <bfHelpIcon text="This is the Live URL to be used for this version"></bfHelpIcon>
            </label>
            <div class="translationRow__editPublishUrlUrl">
                <span class="translationRow__editPublishUrlUrl--notEditable">{{ translation.cName }}</span><span
                      (bfInlineEditDone)="validate();checkDirty();"
                      [(bfInlineEdit)]="publishSlug"
                      [ngStyle]="{ color: translation.publishSlugIsTaken ? '#e65050' : 'inherit' }"
                      bfTooltip="Click to edit"></span><span class="translationRow__editPublishUrlUrl--notEditable">/index.html</span>
                <span class="tinyLoader"
                      *ngIf="translation.isLoading"></span>
            </div>
            <div class="translationRow__editPublishUrlError"
                 *ngIf="translation.publishSlugIsTaken">
                This URL is already used by another landingpage or version, please change the url.
            </div>
        </div>

        <!--PUBLIC URL - show if published-->
        <div class="translationRow__editPublishUrl"
            *ngIf="translation.translation.publishState === 2">
            <label>
                Public URL&nbsp;
                <bfHelpIcon text="This is the Live URL to be used for this version"></bfHelpIcon>
            </label>
            <div class="translationRow__editPublishUrlUrl">
               {{ translation.cName }}{{ translation.translation.publishSlug }}/index.html
            </div>
        </div>

        <p *ngIf="translation.translation.publishState === 2" class="translationRow__publishUrlNoticeText">
            Notice, if you want to edit the public URL you need to unpublish this version first.
        </p>

        <!--TRANSLATION-->
        <div *ngIf="!isOriginal">
            <hr style="margin: 15px 0 15px 0">
            <div class="translationRow__translatorSettings">
                <div *ngIf="showSendForTranslation">
                    <label *ngIf="translation?.isNew || (!isOriginal && translation.translation.translator == null)">
                        Send for translation&nbsp;
                        <bfHelpIcon text="Send the version for translation (optional)"></bfHelpIcon>
                    </label>
                    <div>
                        <span *ngIf="(translation?.isNew || (!translator || translation.translation.translator != translator))">
                            <bfSelect *ngIf="!showNewTranslatorView"
                                      [config]="translation.translatorsAndNone"
                                      [(selected)]="translator"
                                      (selectedChange)="checkDirty()"></bfSelect>
                            <bfSelect *ngIf="showNewTranslatorView"
                                      [config]="translation.translators"
                                      [(selected)]="translator"
                                      (selectedChange)="checkDirty()"></bfSelect>
                        </span>
                        <span *ngIf="(translation?.isNew && translator) || (!translation?.isNew && translator && translation.translation.translator != translator)">
                            <a class="translationRow__showMore"
                               (click)="toggleMoreOptions()">
                                {{showMoreOptions ? 'Less' : 'More'}} options
                            </a>
                        </span>
                    </div>
                </div>
                <div *ngIf="!showSendForTranslation">
                    Please make one or more texts on your Landing Page translatable to enable the send for translation feature
                </div>
            </div>

            <!--DEADLINE-->
            <div *ngIf="(translation?.isNew && translator) || (!translation?.isNew && translator && translation.translation.translator != translator) || showNewTranslatorView"
                 class="translationRow__deadlineOptions">
                <label class="translationRow__deadlineOptionsLabel">
                    <ui-checkbox [selected]="deadlineSpan[0]"
                                (click)="toggleDeadline()"></ui-checkbox>&nbsp;
                    Deadline
                </label>

                <div *ngIf="deadlineSpan[0]">
                    <bfDatePickerButton class="translationRow__datePicker" [(duration)]="deadlineSpan"
                                        [options]="deadlineDatePickerOptions"></bfDatePickerButton>

                    <label style="display: inline-block;" class="translationRow__closeOnDeadline">
                        <ui-checkbox [selected]="closeOnDeadline"
                                    (click)="closeOnDeadline=!closeOnDeadline"></ui-checkbox>&nbsp;
                        Close on deadline
                    </label>
                </div>
            </div>

            <!--MORE OPTIONS-->
            <div *ngIf="showMoreOptions"
                 class="translationRow__moreOptions">
                <div class="translationRow__moreOptionsMessageToTranslator">
                    <label>
                        Message to translator
                    </label>
                    <textarea [(ngModel)]="messageToTranslator"
                              spellcheck="false"
                              rows="5"></textarea>
                </div>
                <div>
                    <label>
                        Notify when complete
                    </label>
                    <bfTagInput [(tags)]="notifyOnDone"
                                [config]="{ placeholder: 'Add email address...' }"></bfTagInput>
                </div>
            </div>
        </div>

    <div class="translationRow__translatorOptions"
         *ngIf="!translation?.isNew && !isOriginal && translation.translation.translator != null && !showNewTranslatorView">
        <div class="translationRow__translator">
            <div class="translationRow__translatorIconContainer">
                <i *ngIf="translation.translation.translationState !== 3"
                    class="translationRow__icon bf-icon bf-icon-email bf-icon-lg"
                    style="margin-top: 2px;"></i>
                <i *ngIf="translation.translation.translationState === 3"
                    class="translationRow__icon translationRow__icon--success bf-icon bf-icon-email"
                    style="font-size: 2rem; margin-top: -1px;"></i>
            </div>
            <div>
                <div *ngIf="translation.translation.translationState !== 3">
                    Sent for translation to
                    {{translation.translation.translator.name}} <span style="color: #999;">({{translation.translation.translator.email}})</span>
                    <div *ngIf="translation.translation.deadline"
                         class="translationRow__translatorDeadline">
                        <strong>Deadline</strong>&nbsp;{{ translation.translation.deadline | date:'yMMMdjms' }}
                    </div>
                </div>
                <div *ngIf="translation.translation.translationState === 3">
                    This translation is completed by
                    {{translation.translation.translator.name}} <span style="color: #999;">({{translation.translation.translator.email}})</span>
                </div>

                <!--if translation is not completed-->
                <div class="translationRow__translatorActionRow"
                     style="margin-top: 3px;">
                    <span *ngIf="translation.translation.translationState !== 3">
                        <a (click)="showConfirmCancelTranslation()">Cancel translation</a>
                        <!--if translation is not closed by deadline-->
                        <span *ngIf="!translation.translation.isClosedByDeadline">
                            &nbsp;|&nbsp;<a (click)="showConfirmSendTranslationRequest()">Resend to translator</a>
                        </span>
                    </span>
                    <a *ngIf="translation.translation.translationState === 3" (click)="sendTranslationToNewTranslator()" class="translation.translation.translationState === 3">
                        New translation
                    </a>
                </div>
                <div class="translationRow__translatorActionRow translationRow__translatorActionRow--translationLink">
                    <a *ngIf="translation.translation.translationState === 2"
                    class="translationRow__gotoTranslationLink"
                    (click)="goToTranslation()">
                        Go to translation page<i class="bf-icon bf-icon-open"></i>
                    </a>
                </div>
            </div>
        </div>


    </div>

        <hr>

        <!--BUTTONS-->
        <div style="margin-top: 15px;">

            <!--CLOSE-->
            <ui-button
                id="interaction-cancel-translation-row-landingpage-btn"
                type="default"
                (click)="closeEdit()"
                text="Cancel">
            </ui-button>&nbsp;

            <!--SAVE (existing)-->
            <ui-button
                *ngIf="!translation?.isNew"
                id="interaction-save-translation-row-landingpage-btn"
                type="primary"
                svgIcon="files"
                [disabled]="!translation.isValid || translation.isLoading || !publishSlug || !isDirty"
                (click)="save()"
                text="Save">
            </ui-button>

            <!--CONFIRM (new)-->
            <ui-button
                *ngIf="translation?.isNew"
                id="interaction-confirm-translation-row-landingpage-btn"
                type="primary"
                [disabled]="!translation.isValid || translation.isLoading || !publishSlug"
                (click)="confirm()"
                text="Confirm">
            </ui-button>

        </div>
    </div>
</td>
